import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { get } from "lodash"
import { createSelector } from "reselect"

export const fetchSalesInfo = createAsyncThunk(
  "apartments/fetchSalesInfo",
  async (payload, { extra: { salesInfoService } }) => {
    return await salesInfoService.getSalesInfo()
  }
)

const INITIAL_STATE = {
  loading: false,
  apartments: {
    byId: {},
    order: []
  }
}

const apartments = createSlice({
  name: "apartments",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [fetchSalesInfo.pending]: (state, action) => {
      state.loading = true
      state.error = undefined
    },
    [fetchSalesInfo.fulfilled]: (state, action) => {
      state.loading = false
      state.apartments = {
        byId: {},
        order: []
      }
    },
    [fetchSalesInfo.rejected]: (state, { error }) => {
      state.loading = false
      state.error = error
    }
  }
})

export const apartmentsSelector = state => get(state, "apartments")

export const orderedApartmentsSelector = createSelector(
  apartmentsSelector,
  ({ byId = {}, order = [] } = {}) => order.map(id => byId[id])
)

export const apartmentsByIdSelector = state => apartmentsSelector(state).byId

export default apartments.reducer
