import { AnimatePresence } from "framer-motion"
import { Switch, Route, Redirect } from "react-router"
import GalleryCarousel from "./GalleryCarousel"
import GalleryGrid from "./GalleryGrid"

const Gallery = ({ match: { url } }) => (
  <AnimatePresence>
    <Switch>
      <Route path={`${url}/carousel`} component={GalleryCarousel} />
      <Route path={`${url}/grid/:page`} component={GalleryGrid} />
      <Route render={() => <Redirect to={`${url}/grid/1`} />} />
    </Switch>
  </AnimatePresence>
)

export default Gallery
