import styled from "styled-components/macro";

export const Root = styled.div`
  transition: opacity 0.5s ease;

  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: scale(${({ isMinimised }) => (isMinimised ? 0 : 1)});
`;

export const Backdrop = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Content = styled.div`
  width: 80%;
  height: 80%;
  background: url('/assets/images/purple_graphic_background.svg');
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;