import React from "react"
import { useGLTF } from "@react-three/drei"
import Cell from "./Cell"

const CellNames = [
  "2A",
  "3A",
  "4A",
  "5A",
  "6A",
  "7A",
  "8A",
  "9A",
  "2B",
  "3B",
  "4B",
  "5B",
  "6B",
  "7B",
  "8B",
  "9B",
  "2C",
  "3C",
  "4C",
  "5C",
  "Penthouse"
]

export default function CornerModel({ onHover, setAnno }) {
  const { nodes, materials } = useGLTF("/assets/models/corner.glb")
  return (
    <group dispose={null} scale={[10, 10, 10]}>
      <mesh geometry={nodes.Image.geometry} material={materials.image} />
      {CellNames.map((name, index) => (
        <Cell
          key={nodes[name].uuid}
          mesh={nodes[name]}
          onHover={onHover}
          setAnno={setAnno}
        ></Cell>
      ))}
    </group>
  )
}

useGLTF.preload("/assets/models/corner.glb")
