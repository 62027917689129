import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { fetchSalesInfo } from "state/apartments"

const SalesInfoPreload = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSalesInfo())
  }, [dispatch])

  return null
}

export default SalesInfoPreload
