class SalesInfoService {
  constructor(config, httpService) {
    this.endpoint = config.kodimeEndpoint
    this.httpService = httpService
  }
  async getSalesInfo() {
    return this.httpService.get({
      url: this.endpoint
    })
  }
}

export default SalesInfoService
