const sherpaBlue = "#1e4f56"
const lynch = "#BCB1B8"
const cascade = "#BDB2B5"
const geyser = "#d4e5e6"
const lotus = "#89373b"
const almostWhite = "#F5F5F5"

const colours = {
  turquoise: {
    veryDark: sherpaBlue,
    dark: lynch,
    mid: cascade,
    light: geyser
  },
  red: {
    dark: lotus
  },
  grey: {
    almostWhite
  }
}

const theme = {
  spacing: n => n * 8,
  palette: {
    ...colours,
    menuFG: colours.grey.almostWhite,
    menuBG: colours.turquoise.mid,
    galleryBG: colours.turquoise.light,
    fg: {
      darkGreen: "#1d4a3d",
      pink: "#7a7a7a",
      darkPink: "#ab68ff",
      cream: "#fceacb",
      test: "#000"
    }
  }
}

export default theme
