import React, { useState } from "react"
import Screen from "components/Screen"

function Team() {
  const [activeIndex, setActiveIndex] = useState(0)

  const onTeamCard = index => {
    if (activeIndex === 0) {
      setActiveIndex(index)
      return
    }

    setActiveIndex(0)
  }

  return (
    <Screen>
      <div className="team">
        <div
          className={`team-card adellco ${activeIndex === 1 ? "active" : null}`}
          onClick={() => onTeamCard(1)}
        >
          {activeIndex !== 1 ? (
            <>
              <span className="big-title">Adellco</span>
              <span className="small-title">Developer</span>
            </>
          ) : (
            <span className="active-text">
              Adellco is a full-service real estate company known for their
              attention to detail and service. Adellco is an Owner, Developer,
              and Manager and has been active in the New York City real estate
              market for 25 years. As one of New York’s premier developers of
              ground-up developments, redevelopments and renovations, Adellco
              has developed several of New York’s most sought after residential
              buildings in neighborhoods throughout Manhattan.
            </span>
          )}
        </div>
        <div
          className={`team-card form ${activeIndex === 2 ? "active" : null}`}
          onClick={() => onTeamCard(2)}
        >
          {activeIndex !== 2 ? (
            <>
              <span className="big-title">FORM4</span>
              <span className="small-title">Architectural Design</span>
            </>
          ) : (
            <span className="active-text">
              FORM4 is a full-service Architecture and Design firm. Their
              passion allows them to work closely with clients and design
              creative solutions that become an enriching experience for the
              users. They have a collective experience of over 30 years in
              architecture in the New York Metro area and are prolific and
              proficient in all aspects of New York architecture, having worked
              with a myriad of city developers, and a plethora of city agencies.
            </span>
          )}
        </div>
        <div
          className={`team-card cabinet ${activeIndex === 3 ? "active" : null}`}
          onClick={() => onTeamCard(3)}
        >
          {activeIndex !== 3 ? (
            <>
              <span className="big-title">Cabinet</span>
              <span className="big-title">Alberto Pinto</span>
              <span className="small-title">Interior Design</span>
            </>
          ) : (
            <span className="active-text">
              Drawing upon the beauty of The Wales’ historic façade, Parisian
              design firm Cabinet Alberto Pinto has imbued interiors with
              timelessly elegant finishes and architectural details. The design
              firm’s sumptuous aesthetic combines rich patterns with finely
              detailed appointments taking cues from founder Alberto Pinto’s
              global travels and his training at the École du Louvre in Paris.
              The award-winning studio’s designs have graced estates, yachts,
              private jets, and, most notably, the private salons of France’s
              Élysée Palace. In addition, the studio made its New York City
              debut with a Parisian-inspired condominium at 27 East 79th Street.
            </span>
          )}
        </div>
        <div
          className={`team-card corcoran ${
            activeIndex === 4 ? "active" : null
          }`}
          onClick={() => onTeamCard(4)}
        >
          {activeIndex !== 4 ? (
            <>
              <span className="big-title">Corcoran Sunshine</span>
              <span className="big-title">Marketing Group</span>
              <span className="small-title">
                Exclusive Marketing and Sales Agent
              </span>
            </>
          ) : (
            <span className="active-text">
              With over 30 years of experience in marketing and collective sales
              of $50 billion, Corcoran Sunshine Marketing Group is a recognized
              industry leader in the planning, design, marketing, and sale of
              luxury residential development. Representing properties throughout
              the United States and in select international locations, Corcoran
              Sunshine Marketing Group’s portfolio contains a curated collection
              of the world’s most desirable new addresses. Corcoran Sunshine
              Marketing Group is the new development arm of The Corcoran Group
              which is part of NRT LLC, the nation’s leading residential real
              estate brokerage company and a subsidiary of Realogy Holdings
              Corp. that operates Realogy’s company-owned real estate brokerage
              offices.
            </span>
          )}
        </div>
      </div>
    </Screen>
  )
}

export default Team
