import { configureStore } from "@reduxjs/toolkit"

import apartments from "./apartments"
import finder from "./finder"
import favourites from "./favourites"

const createStore = dependencies =>
  configureStore({
    reducer: {
      apartments,
      finder,
      favourites
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: dependencies
        }
      })
  })

export default createStore
