import React from "react"

const Context = React.createContext()

const DependencyProvider = ({ dependencies, children }) => (
  <Context.Provider value={dependencies}>{children}</Context.Provider>
)

export const useDependencies = () => {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error("useDependencies must be used within a DependencyProvider")
  }
  return context
}

export default DependencyProvider
