import styled from "styled-components/macro";

export const Root = styled.div`
  transition: opacity 0.5s ease;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: scale(${({ isMinimised }) => (isMinimised ? 0 : 1)});
`;

export const Backdrop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Content = styled.div`
  font-family: Akkurat-Pro-Regular;
  font-size: 0.65rem;
  letter-spacing: 0.75px;
  position: absolute;
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%;
  /* top: 0;
  left: 0;
  height: 80vh;
  transform: translate(50%, 50%); */
  min-width: 60vw;

  display: flex;
  flex-direction: row;
`;

export const ImageContainer = styled.div`
  flex: 2;
  /* width: 500px; */
  /* flex: 1 2 auto; */
  /* min-width: max-content; */
  flex-basis: max-content;
  /* flex-shrink: 1; */
  overflow: hidden;
`;

export const TextContainer = styled.div`
  font-family: Akkurat-Pro-Regular;
  flex: 1;
  max-width: 400px;
  padding: ${({ theme: { spacing } }) => spacing(3)}px;

  font-size: 1.5em;
  line-height: 1.5em;
  background-color: ${({
    theme: {
      palette: {
        fg: { darkPink },
      },
    },
  }) => darkPink};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Title = styled.div`
  font-family: Tungsten;

  margin-top: 2em;
  text-transform: uppercase;
  font-size: 1.9em;
  letter-spacing: 0.3em;
  color: ${({
    theme: {
      palette: {
        fg: { cream },
      },
    },
  }) => cream};
`;
