import { motion } from "framer-motion"
import { GALLERYS } from "./data"
import Div100vh from "react-div-100vh"
import { Swiper, SwiperSlide } from "swiper/react"
import { IconButton } from "@material-ui/core"

// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
// import Swiper core and required modules
import SwiperCore, { Mousewheel, Pagination } from "swiper/core"

// install Swiper modules
SwiperCore.use([Mousewheel, Pagination])

const Gallery = props => {
  const { floor, onSidebar } = props
  const gallerys = GALLERYS[floor]

  if (!gallerys) return <></>

  return (
    <motion.div className="gallery" initial="initial">
      <Div100vh className="gallery-container">
        <Swiper
          pagination={{
            clickable: true
          }}
          mousewheel={true}
          className="explore_content_swiper"
        >
          {gallerys.map((item, index) => (
            <SwiperSlide className="gallery_content_swiper_slide" key={index}>
              <img src={`/assets/images/gallery/${item}.jpg`} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
        <IconButton onClick={() => onSidebar(0)} className="back">
          <img src="/assets/images/icon/back_arrow.svg" alt="back" />
        </IconButton>
      </Div100vh>
    </motion.div>
  )
}

export default Gallery
