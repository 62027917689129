export const SIDEBAR_VALUE = {
  FLOOR_PLAN: 1,
  GALLERY: 2,
  VIEW: 3,
  TOUR: 4,
  BROCHURE: 5,
  SHARE: 6
}

export const APARTMENT_DATA = {
  "2A": {
    residence_number: "2A",
    residence_type: "Condominium Residence",
    bedrooms: "4",
    bathrooms: 4.5,
    addtional_rooms: "Powder Room \nLibrary",
    sqft_interior: "2,974 Sq. Ft.",
    sqm_interior: "276.3 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Gracious Entry Foyer and Gallery\nCorner Great Room with Open Southwest Exposure\nCustom-designed Kitchen by Cabinet Alberto Pinto\nPrimary Bedroom Suite with Five-fixture Bathroom \nand Walk-in Closet\nSecondary Bedrooms with Ensuite Bathrooms\nLaundry Room with Sink",
    view: "South & West Exposures",
    status: "Available",
    pdf_page: 1,
    plan_image: "1-crop.png"
  },
  "2B": {
    residence_number: "2B",
    residence_type: "Condominium Residence",
    bedrooms: "2",
    bathrooms: 2.5,
    addtional_rooms: "Powder Room",
    sqft_interior: "1,772 Sq. Ft.",
    sqm_interior: "164.6 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Great Room Overlooking Madison Avenue\nCustom-designed Kitchen by Cabinet Alberto Pinto\nPrimary Bedroom Suite with Five-fixture Bathroom \nand Walk-in Closet\nSecondary Bedroom with Ensuite Bathroom\nLaundry Closet with Sink",
    view: "West Exposure",
    status: "Available",
    pdf_page: 2,
    plan_image: "2-crop.png"
  },
  "2C": {
    residence_number: "2C",
    residence_type: "Studio",
    bedrooms: "Studio",
    bathrooms: 1,
    addtional_rooms: "",
    sqft_interior: "466 Sq. Ft.",
    sqm_interior: "43.3 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details: "",
    view: "South Exposure",
    status: "Available",
    pdf_page: 3,
    plan_image: "3-crop.png"
  },
  "3A": {
    residence_number: "3A",
    residence_type: "Condominium Residence",
    bedrooms: "4",
    bathrooms: 4.5,
    addtional_rooms: "Powder Room \nLibrary",
    sqft_interior: "2,974 Sq. Ft.",
    sqm_interior: "276.3 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Gracious Entry Foyer and Gallery\nCorner Great Room with Open Southwest Exposure\nCustom-designed Kitchen by Cabinet Alberto Pinto\nPrimary Bedroom Suite with Five-fixture Bathroom \nand Walk-in Closet\nSecondary Bedrooms with Ensuite Bathrooms\nLaundry Room with Sink",
    view: "South & West Exposures",
    status: "Available",
    pdf_page: 1,
    plan_image: "1-crop.png"
  },
  "3B": {
    residence_number: "3B",
    residence_type: "Condominium Residence",
    bedrooms: "2",
    bathrooms: 2.5,
    addtional_rooms: "Powder Room",
    sqft_interior: "1,772 Sq. Ft.",
    sqm_interior: "164.6 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Great Room Overlooking Madison Avenue\nCustom-designed Kitchen by Cabinet Alberto Pinto\nPrimary Bedroom Suite with Five-fixture Bathroom \nand Walk-in Closet\nSecondary Bedroom with Ensuite Bathroom\nLaundry Closet with Sink",
    view: "West Exposure",
    status: "Available",
    pdf_page: 2,
    plan_image: "2-crop.png"
  },
  "3C": {
    residence_number: "3C",
    residence_type: "Studio",
    bedrooms: "Studio",
    bathrooms: 1,
    addtional_rooms: "",
    sqft_interior: "466 Sq. Ft.",
    sqm_interior: "43.3 Sq. M",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details: "",
    view: "South Exposure",
    status: "Available",
    pdf_page: 3,
    plan_image: "3-crop.png"
  },
  "4A": {
    residence_number: "4A",
    residence_type: "Condominium Residence",
    bedrooms: "3",
    bathrooms: 3.5,
    addtional_rooms: "Powder Room",
    sqft_interior: "2,184 Sq. Ft.",
    sqm_interior: "202.9 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Gracious Foyer and Entry Gallery\nCorner Great Room with Open Southwest Exposure\nCustom-designed Kitchen by Cabinet Alberto Pinto\nPrimary Bedroom Suite with Five-fixture Bathroom \nand Dual Walk-in Closets\nSecondary Bedrooms with Ensuite Bathrooms\nLaundry Room with Sink and Storage Closet",
    view: "South & West Exposures",
    status: "Available",
    pdf_page: 4,
    plan_image: "4-crop.png"
  },
  "4B": {
    residence_number: "4B",
    residence_type: "Condominium Residence",
    bedrooms: "2",
    bathrooms: 2.5,
    addtional_rooms: "Powder Room",
    sqft_interior: "1,715 Sq. Ft.",
    sqm_interior: "159.3 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Great Room Overlooking Madison Avenue \nCustom-designed Kitchen by Cabinet Alberto Pinto \nPrimary Bedroom Suite with Five-fixture Bathroom \nand Dual Walk-in Closets \nSecondary Bedrooms with Ensuite Bathrooms \nLaundry Room with Sink",
    view: "West Exposure",
    status: "Available",
    pdf_page: 5,
    plan_image: "5-crop.png"
  },
  "4C": {
    residence_number: "4C",
    residence_type: "Condominium Residence",
    bedrooms: "2",
    bathrooms: 2.5,
    addtional_rooms: "Powder Room",
    sqft_interior: "1,393 Sq. Ft.",
    sqm_interior: "129.4 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Great Room Overlooking Tree-lined E. 92nd Street \nCustom-designed Kitchen by Cabinet Alberto Pinto \nPrimary Bedroom Suite with Windowed Bathroom \nand Walk-in Closet \nSecondary Bedroom with Ensuite Bathroom Washer/Dryer",
    view: "South Exposure",
    status: "Available",
    pdf_page: 6,
    plan_image: "6-crop.png"
  },
  "5A": {
    residence_number: "5A",
    residence_type: "Condominium Residence",
    bedrooms: "3",
    bathrooms: 3.5,
    addtional_rooms: "Powder Room",
    sqft_interior: "2,184 Sq. Ft.",
    sqm_interior: "202.9 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Gracious Foyer and Entry Gallery\nCorner Great Room with Open Southwest Exposure\nCustom-designed Kitchen by Cabinet Alberto Pinto\nPrimary Bedroom Suite with Five-fixture Bathroom \nand Dual Walk-in Closets\nSecondary Bedrooms with Ensuite Bathrooms\nLaundry Room with Sink and Storage Closet",
    view: "South & West Exposures",
    status: "Available",
    pdf_page: 4,
    plan_image: "4-crop.png"
  },
  "5B": {
    residence_number: "5B",
    residence_type: "Condominium Residence",
    bedrooms: "2",
    bathrooms: 2.5,
    addtional_rooms: "Powder Room",
    sqft_interior: "1,715 Sq. Ft.",
    sqm_interior: "159.3 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Great Room Overlooking Madison Avenue \nCustom-designed Kitchen by Cabinet Alberto Pinto \nPrimary Bedroom Suite with Five-fixture Bathroom \nand Dual Walk-in Closets \nSecondary Bedrooms with Ensuite Bathrooms \nLaundry Room with Sink",
    view: "West Exposure",
    status: "Available",
    pdf_page: 5,
    plan_image: "5-crop.png"
  },
  "5C": {
    residence_number: "5C",
    residence_type: "Condominium Residence",
    bedrooms: "2",
    bathrooms: 2.5,
    addtional_rooms: "Powder Room",
    sqft_interior: "1,393 Sq. Ft.",
    sqm_interior: "129.4 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Great Room Overlooking Tree-lined E. 92nd Street \nCustom-designed Kitchen by Cabinet Alberto Pinto \nPrimary Bedroom Suite with Windowed Bathroom \nand Walk-in Closet \nSecondary Bedroom with Ensuite Bathroom Washer/Dryer",
    view: "South Exposure",
    status: "Available",
    pdf_page: 6,
    plan_image: "6-crop.png"
  },
  "6A": {
    residence_number: "6A",
    residence_type: "Condominium Residence",
    bedrooms: "4",
    bathrooms: 4.5,
    addtional_rooms: "Powder Room \nLibrary",
    sqft_interior: "2,936 Sq. Ft.",
    sqm_interior: "272.8 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Half-floor Residence\nGracious Entry Foyer and Gallery\nCorner Great Room with Open Southwest Exposure\nCustom-designed Kitchen by Cabinet Alberto Pinto\nPrimary Bedroom suite with Windowed Five-fixture Bathroom \nand Dual Walk-in Closets\nSecondary Bedrooms with Ensuite Bathrooms\nWet Bar with Undercounter Refrigerator\nLaundry Room with Sink",
    view: "South & West Exposures",
    status: "Available",
    pdf_page: 7,
    plan_image: "7-crop.png"
  },
  "6B": {
    residence_number: "6B",
    residence_type: "Condominium Residence",
    bedrooms: "3",
    bathrooms: 3.5,
    addtional_rooms: "Powder Room",
    sqft_interior: "2,379 Sq. Ft.",
    sqm_interior: "221.0 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Half-floor Residence\nGracious Entry Foyer\nCorner Great Room Overlooking Madison Avenue\nCustom-designed Kitchen by Cabinet Alberto Pinto \nwith Service Entrance\nPrimary Bedroom Suite with Five-fixture Bathroom \nand Walk-in Closet\nSecondary Bedrooms with Ensuite Bathrooms\nWet Bar with Undercounter Refrigerator\nLaundry Room with Sink",
    view: "North & West Exposures",
    status: "Available",
    pdf_page: 8,
    plan_image: "8-crop.png"
  },
  "7A": {
    residence_number: "7A",
    residence_type: "Condominium Residence",
    bedrooms: "4",
    bathrooms: 4.5,
    addtional_rooms: "Powder Room \nLibrary",
    sqft_interior: "2,936 Sq. Ft.",
    sqm_interior: "272.8 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Half-floor Residence\nGracious Entry Foyer and Gallery\nCorner Great Room with Open Southwest Exposure\nCustom-designed Kitchen by Cabinet Alberto Pinto\nPrimary Bedroom suite with Windowed\nFive-fixture Bathroom and Dual Walk-in Closets\nSecondary Bedrooms with Ensuite Bathrooms\nWet Bar with Undercounter Refrigerator\nLaundry Room with Sink",
    view: "South & West Exposures",
    status: "Available",
    pdf_page: 7,
    plan_image: "7-crop.png"
  },
  "7B": {
    residence_number: "7B",
    residence_type: "Condominium Residence",
    bedrooms: "3",
    bathrooms: 3.5,
    addtional_rooms: "Powder Room",
    sqft_interior: "2,379 Sq. Ft.",
    sqm_interior: "221.0 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Half-floor Residence\nGracious Entry Foyer\nCorner Great Room Overlooking Madison Avenue\nCustom-designed Kitchen by Cabinet Alberto Pinto \nwith Service Entrance\nPrimary Bedroom Suite with Five-fixture Bathroom \nand Walk-in Closet\nSecondary Bedrooms with Ensuite Bathrooms\nWet Bar with Undercounter Refrigerator\nLaundry Room with Sink",
    view: "North & West Exposures",
    status: "Available",
    pdf_page: 8,
    plan_image: "8-crop.png"
  },
  "8A": {
    residence_number: "8A",
    residence_type: "Condominium Residence",
    bedrooms: "4",
    bathrooms: 4.5,
    addtional_rooms: "Powder Room \nLibrary",
    sqft_interior: "2,936 Sq. Ft.",
    sqm_interior: "272.8 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Half-floor Residence\nGracious Entry Foyer and Gallery\nCorner Great Room with Open Southwest Exposure\nCustom-designed Kitchen by Cabinet Alberto Pinto\nPrimary Bedroom suite with Windowed Five-fixture Bathroom \nand Dual Walk-in Closets\nSecondary Bedrooms with Ensuite Bathrooms\nWet Bar with Undercounter Refrigerator\nLaundry Room with Sink",
    view: "South & West Exposures",
    status: "Available",
    pdf_page: 7,
    plan_image: "7-crop.png"
  },
  "8B": {
    residence_number: "8B",
    residence_type: "Condominium Residence",
    bedrooms: "3",
    bathrooms: 3.5,
    addtional_rooms: "Powder Room",
    sqft_interior: "2,379 Sq. Ft.",
    sqm_interior: "221.0 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Half-floor Residence\nGracious Entry Foyer\nCorner Great Room Overlooking Madison Avenue\nCustom-designed Kitchen by Cabinet Alberto Pinto \nwith Service Entrance\nPrimary Bedroom Suite with Five-fixture Bathroom \nand Walk-in Closet\nSecondary Bedrooms with Ensuite Bathrooms\nWet Bar with Undercounter Refrigerator\nLaundry Room with Sink",
    view: "North & West Exposures",
    status: "Available",
    pdf_page: 8,
    plan_image: "8-crop.png"
  },
  "9A": {
    residence_number: "9A",
    residence_type: "Condominium Residence",
    bedrooms: "4",
    bathrooms: 4.5,
    addtional_rooms: "Powder Room \nLibrary",
    sqft_interior: "2,936 Sq. Ft.",
    sqm_interior: "272.8 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Half-floor Residence\nGracious Entry Foyer and Gallery\nCorner Great Room with Open Southwest Exposure\nCustom-designed Kitchen by Cabinet Alberto Pinto\nPrimary Bedroom suite with Windowed\nFive-fixture Bathroom and Dual Walk-in Closets\nSecondary Bedrooms with Ensuite Bathrooms\nWet Bar with Undercounter Refrigerator\nLaundry Room with Sink",
    view: "South & West Exposures",
    status: "Available",
    pdf_page: 7,
    plan_image: "7-crop.png"
  },
  "9B": {
    residence_number: "9B",
    residence_type: "Condominium Residence",
    bedrooms: "3",
    bathrooms: 3.5,
    addtional_rooms: "Powder Room",
    sqft_interior: "2,379 Sq. Ft.",
    sqm_interior: "221.0 Sq. M.",
    "Sqft Exterior": "",
    "Sq M Exterior": "",
    details:
      "Half-floor Residence\nGracious Entry Foyer\nCorner Great Room Overlooking Madison Avenue\nCustom-designed Kitchen by Cabinet Alberto Pinto \nwith Service Entrance\nPrimary Bedroom Suite with Five-fixture\nBathroom and Walk-in Closet\nSecondary Bedrooms with Ensuite Bathrooms\nWet Bar with Undercounter Refrigerator\nLaundry Room with Sink",
    view: "North & West Exposures",
    status: "Available",
    pdf_page: 8,
    plan_image: "8-crop.png"
  },
  Penthouse: {
    residence_number: "Penthouse",
    residence_type: "Penthouse",
    bedrooms: "5",
    bathrooms: 6,
    addtional_rooms: "Two Powder Rooms",
    sqft_interior: "4,179 Sq. Ft.",
    sqm_interior: "388.2 Sq. M.",
    "Sqft Exterior": "3,114 Sq. Ft.",
    "Sq M Exterior": "289.3 Sq. M.",
    details:
      "Full-floor Residence\nWraparound Terrace on Main Level\nPrivate Roof Terrace with Butler´s Pantry, \nOutdoor Fireplace, Outdoor Kitchen, and Wet Bar\nDramatic Entry Gallery with Elegant\nArchitectural Stair to Roof Terrace\nPrivate Elevator from Main Level to\nRoof Terrace\nGracious Corner Great Room\nwith Terrace Access\nCustom-designed Windowed Eat-in Kitchen\nby Cabinet Alberto Pinto with Terrace Access\nand Outdoor Grill\nPrimary Bedroom Suite with Five-fixture\nBathroom, Windowed Walk-in Closet, and\nTerrace Access\nSecondary Bedrooms with Ensuite Bathrooms\nLaundry Room with Sink",
    view: "North, South, East & West Exposures",
    status: "Available",
    pdf_page: 9,
    plan_image: "9-crop.png"
  }
}

export const GALLERYS = {
  "2A": ["image-06", "image-07", " image-04", "image-08", "image-09"],
  "2B": ["image-05", "image-08", "image-09"],
  "2C": ["image-09"],
  "3A": ["image-06", "image-07", "image-04", "image-08", "image-09"],
  "3B": ["image-05", "image-08", "image-09"],
  "3C": ["image-09"],
  "4A": ["image-06", "image-07", "image-04", "image-08", "image-09"],
  "4B": ["image-05", "image-08", "image-09"],
  "4C": ["image-05", "image-07", "image-08", "image-09"],
  "5A": ["image-06", "image-07", "image-04", "image-08", "image-09"],
  "5B": ["image-05", "image-08", "image-09"],
  "5C": ["image-05", "image-07", "image-08", "image-09"],
  "6A": ["image-06", "image-07", "image-04", "image-08", "image-09"],
  "6B": ["image-05", "image-08", "image-09"],
  "7A": ["image-06", "image-07", "image-04", "image-08", "image-09"],
  "7B": ["image-05", "image-08", "image-09"],
  "8A": ["image-06", "image-07", "image-04", "image-08", "image-09"],
  "8B": ["image-05", "image-08", "image-09"],
  "9A": ["image-06", "image-07", "image-04", "image-08", "image-09"],
  "9B": ["image-05", "image-08", "image-09"],
  Penthouse: [
    "image-02",
    "image-07",
    "image-03",
    "image-08",
    "image-09",
    "image-01"
  ]
}

export const VIEWS = {
  "2A": ["01", "02"],
  "2B": ["01"],
  "2C": [],
  "3A": ["01", "02"],
  "3B": ["01"],
  "3C": ["01"],
  "4A": ["01", "02"],
  "4B": ["01"],
  "4C": ["01"],
  "5A": ["01", "02"],
  "5B": ["01"],
  "5C": ["01"],
  "6A": ["01", "02"],
  "6B": ["01"],
  "6C": ["01"],
  "7A": ["01", "02", "03"],
  "7B": ["01"],
  "7C": [],
  "8A": ["01", "02", "03"],
  "8B": ["01"],
  "8C": [],
  "9A": ["01", "02", "03"],
  "9B": ["01"],
  "9C": [],
  Penthouse: ["01", "02", "03", "04"]
}
