import { motion } from "framer-motion"
import { APARTMENT_DATA } from "../Residences/data"
import { IconButton } from "@material-ui/core"
import FloorPlanContent from "screens/Residences/FloorPlanContent"

const pageVariants = {
  initial: {
    x: "-100%"
  },
  in: {
    x: 0
  },
  out: {
    x: "-100%"
  }
}

const pageTransition = {
  ease: "easeInOut",
  duration: 0.5
}

const FloorPlan = props => {
  const { floor, onClose } = props
  const floor_data = APARTMENT_DATA[floor]

  if (!floor_data) return <></>

  return (
    <motion.div
      className="floor-plan"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <FloorPlanContent data={floor_data} />
      <IconButton className="close-btn" onClick={onClose}>
        <img src="/assets/images/icon/back_arrow.svg" alt="back" />
      </IconButton>
    </motion.div>
  )
}

export default FloorPlan
