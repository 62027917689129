import React, { useState } from "react"
import { motion } from "framer-motion"
import { Link } from "react-router-dom"
import Div100vh from "react-div-100vh"
import { Swiper, SwiperSlide } from "swiper/react"
import { useWindowHeight } from "@react-hook/window-size"
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit"

// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
// import Swiper core and required modules
import SwiperCore, { Mousewheel, Pagination } from "swiper/core"
import { toggleFullScreen } from "../../util"

// install Swiper modules
SwiperCore.use([Mousewheel, Pagination])

const pageVariants = {
  init: {
    x: 0
  },
  show: {
    x: 0
  },
  hidden: {
    x: "-100vw"
  }
}

const MenuTransition = {
  ease: "easeOut",
  duration: 1
}

function Explore() {
  const onlyHeight = useWindowHeight()
  const [isOpen, setIsOpen] = useState(true)
  const exitFullScreen =
    (document.fullScreenElement !== undefined &&
      document.fullScreenElement === null) ||
    (document.msFullscreenElement !== undefined &&
      document.msFullscreenElement === null) ||
    (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
    (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)

  const onFullScreen = () => {
    toggleFullScreen(document.body)
  }

  return (
    <Div100vh className="explore">
      <motion.div
        className="explore_menu"
        initial="initial"
        animate={isOpen ? "show" : "hidden"}
        variants={pageVariants}
        transition={MenuTransition}
      >
        <div className="logo" onClick={() => setIsOpen(false)}>
          <img src="/assets/images/landing/logo.png" alt="logo" />
        </div>
        {exitFullScreen ? (
          <FullscreenIcon className="fullscreen" onClick={onFullScreen} />
        ) : (
          <FullscreenExitIcon className="fullscreen" onClick={onFullScreen} />
        )}
      </motion.div>
      {!isOpen && (
        <div className="explore_content">
          <Swiper
            direction={"vertical"}
            pagination={{
              clickable: true
            }}
            mousewheel={true}
            className="explore_content_swiper"
          >
            <SwiperSlide className="explore_content_swiper_slide">
              <Link
                to={`/lobby`}
                className="first_marker"
                style={{
                  bottom: (110 * onlyHeight) / 1330,
                  left: `calc(50% - ${(415 * onlyHeight) / 1330}px)`
                }}
              ></Link>
              <img
                src="/assets/images/gallery/gallery-image-01.jpg"
                alt=""
              ></img>
            </SwiperSlide>
            <SwiperSlide className="explore_content_swiper_slide">
              <Link
                to={`/lobby`}
                className="second_marker"
                style={{
                  bottom: (110 * onlyHeight) / 1330,
                  left: `calc(50% - ${(455 * onlyHeight) / 1330}px)`
                }}
              ></Link>
              <img
                src="/assets/images/gallery/gallery-image-02.jpg"
                alt=""
              ></img>
            </SwiperSlide>
          </Swiper>
        </div>
      )}
      <Link to={`/residences`} className="explore_skip">
        Skip
      </Link>
    </Div100vh>
  )
}

export default Explore
