import styled from "styled-components/macro"
import { OpenToggleButton } from "./Toggle"

export const LinkGroup = styled.div`
  width: 100%;
  height: 100%;
  list-style-type: none;
  margin: 0;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #2e1a47;
  padding-top: 150px;
  padding-bottom: 100px;
`

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

export const LinkItem = styled.span`
  font-size: 5vw;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
`

export const OpenToggle = styled(OpenToggleButton)`
  position: fixed;
  top: 1em;
  left: 1em;
  width: 5vw;
  min-width: 3em;
  max-width: 5em;
  height: 5vw;
  min-height: 3em;
  z-index: 2001;
  transform: translateX(0%);
`
