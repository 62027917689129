import { motion } from "framer-motion"
import { APARTMENT_DATA, SIDEBAR_VALUE } from "./data"
import FloorPlanContent from "./FloorPlanContent"

const pageVariants = {
  initial: {
    x: "-100%"
  },
  in: {
    x: 0
  },
  out: {
    x: "-100%"
  }
}

const pageTransition = {
  ease: "easeInOut",
  duration: 0.5
}

export default function FloorPlan(props) {
  const { floor, onSidebar } = props
  const floor_data = APARTMENT_DATA[floor]

  if (!floor_data) {
    return <></>
  }

  return (
    <motion.div
      className="floor-plan"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <FloorPlanContent data={floor_data} />
      <div className="back-container">
        <img
          src="/assets/images/icon/back_arrow.svg"
          alt="back"
          className="back"
          onClick={() => onSidebar(0)}
        />
        <div className="action-bar">
          <img
            className="action"
            src="/assets/images/icon/floorplan_icon_outline.svg"
            alt="back"
            onClick={() => onSidebar(SIDEBAR_VALUE.FLOOR_PLAN)}
          />
          <img
            className="action"
            src="/assets/images/icon/gallery_icon__fill.svg"
            alt="back"
            onClick={() => onSidebar(SIDEBAR_VALUE.GALLERY)}
          />
          <img
            className="action"
            src="/assets/images/icon/view_icon__fill.svg"
            alt="back"
            onClick={() => {
              onSidebar(SIDEBAR_VALUE.VIEW)
            }}
          />
          <img
            className="action"
            src="/assets/images/icon/360_icon_fill.svg"
            alt="back"
            onClick={() => onSidebar(SIDEBAR_VALUE.TOUR)}
          />
          <img
            className="action"
            src="/assets/images/icon/brochure_icon_fill.svg"
            alt="back"
            onClick={() => onSidebar(SIDEBAR_VALUE.BROCHURE)}
          />
          <img
            className="action"
            src="/assets/images/icon/share_icon_fill.svg"
            alt="back"
            onClick={() => onSidebar(SIDEBAR_VALUE.SHARE)}
          />
        </div>
      </div>
    </motion.div>
  )
}
