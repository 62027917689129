import React from "react"
import { motion } from "framer-motion"
import { SIDEBAR_VALUE, APARTMENT_DATA } from "./data"
import { IconButton } from "@material-ui/core"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"
const pageVariants = {
  init: {
    x: "-490px"
  },
  show: {
    x: 0
  },
  hidden: {
    x: "-490px"
  }
}

const MenuTransition = {
  ease: "easeOut",
  duration: 0.6
}

function LeftSideBar(props) {
  const { isOpen, floor, setOpen, onCallback } = props
  const floor_data = APARTMENT_DATA[floor]

  return (
    <motion.div
      className="left-bar"
      initial="init"
      animate={isOpen ? "show" : "hidden"}
      variants={pageVariants}
      transition={MenuTransition}
    >
      {floor_data ? (
        <>
          <div className="header-container">
            <IconButton className="back-button" onClick={() => setOpen(false)}>
              <img src="/assets/images/icon/back_arrow.svg" alt="back" />
            </IconButton>
            <span className="header-title">
              {floor_data.residence_number === "Penthouse"
                ? `${floor_data.residence_number} Residence`
                : `Residence ${floor_data.residence_number}`}
            </span>
          </div>
          <TransformWrapper>
            <TransformComponent
              wrapperStyle={{
                width: "100%",
                flex: 1,
                backgroundColor: "#ffffff",
                cursor: "zoom-in"
              }}
              contentStyle={{ width: "100%", height: "100%" }}
            >
              <div className="floor-area">
                <img
                  src={`/assets/images/residences/floor_plan/${floor_data.plan_image}`}
                  alt=""
                />
              </div>
            </TransformComponent>
          </TransformWrapper>
          <div className="rooms-area">
            <div className="room">
              <span className="room-title">Bedrooms</span>
              <span className="room-count">{floor_data.bedrooms}</span>
            </div>
            <div className="room">
              <span className="room-title">Bathrooms</span>
              <span className="room-count">{floor_data.bathrooms}</span>
            </div>
          </div>
          <div className="interior">{`${floor_data.sqft_interior} / ${floor_data.sqm_interior}`}</div>
          <div className="view">{floor_data.view}</div>
          <div className="action-container">
            <img
              className="action"
              onClick={() => onCallback(SIDEBAR_VALUE.FLOOR_PLAN)}
              src="/assets/images/icon/floorplan_icon_outline.svg"
              alt="back"
            />
            <img
              className="action"
              onClick={() => onCallback(SIDEBAR_VALUE.GALLERY)}
              src="/assets/images/icon/gallery_icon__fill.svg"
              alt="back"
            />
            <img
              className="action"
              onClick={() => {
                onCallback(SIDEBAR_VALUE.VIEW)
              }}
              src="/assets/images/icon/view_icon__fill.svg"
              alt="back"
            />
            <img
              className="action"
              onClick={() => onCallback(SIDEBAR_VALUE.TOUR)}
              src="/assets/images/icon/360_icon_fill.svg"
              alt="back"
            />
            <img
              className="action"
              onClick={() => onCallback(SIDEBAR_VALUE.BROCHURE)}
              src="/assets/images/icon/brochure_icon_fill.svg"
              alt="back"
            />
            <img
              className="action"
              onClick={() => onCallback(SIDEBAR_VALUE.SHARE)}
              src="/assets/images/icon/share_icon_fill.svg"
              alt="back"
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </motion.div>
  )
}

export default LeftSideBar
