import Gallery3D from "components/Gallery3D"
import AppsIcon from "@material-ui/icons/Apps"
import { IconButton } from "@material-ui/core"
import { motion } from "framer-motion"

const Start = 0.5
const Tiles = [
  {
    path: "/assets/images/carnegie/carnegie-01.jpg",
    label: "",
    pos: {
      x: 0 + Start,
      y: 0.2
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-02.jpg",
    label: "",
    pos: {
      x: 2.6 + Start,
      y: -0.3
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-03.jpg",
    label: "",
    pos: {
      x: 4.8 + Start,
      y: 0
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-04.jpg",
    label: "",
    pos: {
      x: 7.5 + Start,
      y: -0.3
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-05.jpg",
    label: "",
    pos: {
      x: 9.6 + Start,
      y: 0.2
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-06.jpg",
    label: "",
    pos: {
      x: 11.6 + Start,
      y: 0
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-07.jpg",
    label: "",
    pos: {
      x: 13.4 + Start,
      y: 0.2
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-08.jpg",
    label: "",
    pos: {
      x: 16 + Start,
      y: -0.3
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-09.jpg",
    label: "",
    pos: {
      x: 18.6 + Start,
      y: 0.2
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-10.jpg",
    label: "",
    pos: {
      x: 20.6 + Start,
      y: -0.3
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-11.jpg",
    label: "",
    pos: {
      x: 22.6 + Start,
      y: 0.2
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-12.jpg",
    label: "",
    pos: {
      x: 25 + Start,
      y: -0.3
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-13.jpg",
    label: "",
    pos: {
      x: 27.4 + Start,
      y: 0
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-14.jpg",
    label: "",
    pos: {
      x: 29.6 + Start,
      y: 0.1
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-15.jpg",
    label: "",
    pos: {
      x: 32 + Start,
      y: -0.3
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-16.jpg",
    label: "",
    pos: {
      x: 34 + Start,
      y: 0.1
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-17.jpg",
    label: "",
    pos: {
      x: 36.6 + Start,
      y: -0.3
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-18.jpg",
    label: "",
    pos: {
      x: 39.2 + Start,
      y: -0.1
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-19.jpg",
    label: "",
    pos: {
      x: 41.6 + Start,
      y: 0
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-20.jpg",
    label: "",
    pos: {
      x: 43.6 + Start,
      y: -0.3
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-21.jpg",
    label: "",
    pos: {
      x: 46.2 + Start,
      y: -0.2
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-22.jpg",
    label: "",
    pos: {
      x: 48.2 + Start,
      y: 0.2
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-23.jpg",
    label: "",
    pos: {
      x: 50.2 + Start,
      y: 0
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-24.jpg",
    label: "",
    pos: {
      x: 52.4 + Start,
      y: -0.1
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-25.jpg",
    label: "",
    pos: {
      x: 54.8 + Start,
      y: 0.2
    }
  },
  {
    path: "/assets/images/carnegie/carnegie-26.jpg",
    label: "",
    pos: {
      x: 56.8 + Start,
      y: -0.1
    }
  },
  {
    path: "/assets/images/map/map.jpg",
    label: "",
    pos: {
      x: 58.7 + Start,
      y: 0.2
    }
  }
]
const Title = {
  main: "",
  sub: ""
}

const pageVariants = {
  init: {
    opacity: 0
  },
  show: {
    opacity: 1
  },
  hidden: {
    opacity: 0
  }
}

const pageTransition = {
  ease: "easeOut",
  duration: 1
}

export default function CarnegieCarousel({ history }) {
  const handleToggle = () => {
    history.replace("/carnegie/grid/1")
  }
  return (
    <motion.div
      className="gallery-carousel"
      variants={pageVariants}
      transition={pageTransition}
      animate="show"
    >
      <IconButton onClick={handleToggle} className="grid-button">
        <AppsIcon fontSize="large" />
      </IconButton>
      <Gallery3D title={Title} tiles={Tiles} speed={0} length={22.4} />
    </motion.div>
  )
}
