import Explore from "./screens/Explore"
import Residences from "./screens/Residences"
import Carnegie from "./screens/Carnegie"
import Media from "./screens/Media"
import Team from "./screens/Team"

export const primaryRoutes = [
  {
    name: "The Wales",
    exact: true,
    path: "/",
    component: Explore
  },
  {
    name: "Residences",
    path: "/residences",
    component: Residences
  },
  {
    name: "Carnegie Hill",
    path: "/carnegie",
    component: Carnegie
  },
  {
    name: "Media",
    path: "/media",
    component: Media
  },
  {
    name: "Team",
    path: "/team",
    component: Team
  }
]

export const home = primaryRoutes[0]
