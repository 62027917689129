import { useRef, useEffect, useState } from "react"
import { Root, Backdrop, Content } from "./ModalImage.style"

const GalleryImage = ({ url, label, text, onClose }) => {
  // cache the props in a ref so we still have them available
  // after the parent has removed them (so we can fade out)
  const data = useRef({ url, label, text })

  const [isMinimised, setIsMinimised] = useState(!url)

  useEffect(() => {
    if (url) {
      data.current = { url, label, text }
      setIsMinimised(false)
    } else {
      // size to 0 after fade-out is complete
      setTimeout(() => setIsMinimised(true), 500)
    }
  }, [url, label, text])

  return (
    <Root isVisible={!!url} isMinimised={isMinimised} onClick={onClose}>
      <Backdrop />
      <Content>
        <img
          src={data.current.url}
          alt=""
          style={{ width: "auto", maxHeight: "100%" }}
        ></img>
      </Content>
    </Root>
  )
}

export default GalleryImage
