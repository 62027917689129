import { useState, useCallback } from "react"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
// eslint-disable-next-line
import Div100vh from "react-div-100vh"
import {
  LinkGroup,
  LinkContainer,
  LinkItem,
  OpenToggle
} from "./NavigationMenu.style"
import "./NavigationMenu.scss"
import { motion } from "framer-motion"

const MenuVariants = {
  init: {
    x: "-100vw"
  },
  show: {
    x: 0
  },
  hidden: {
    x: "-100vw"
  }
}

const MenuTransition = {
  ease: "easeOut",
  duration: 0.5
}

const PURPLE_MENUS = ["/residences", "/map"]

const NavigationMenu = ({ routes }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleMenu = useCallback(() => {
    setIsOpen(open => !open)
  }, [])

  const { pathname } = useLocation()
  const { path: currentLocation } =
    routes.find(({ path }) => pathname.startsWith(path)) || routes[0]

  if (pathname === "/select_floor_plans") return <></>

  return (
    <>
      <OpenToggle
        onClick={handleToggleMenu}
        isPurple={
          PURPLE_MENUS.find(menu => menu === pathname)
            ? !isOpen && true
            : isOpen && false
        }
      />
      <motion.div
        variants={MenuVariants}
        initial="init"
        animate={isOpen ? "show" : "hidden"}
        transition={MenuTransition}
        className="menu-motion"
      >
        <LinkGroup>
          <LinkContainer>
            {routes.map(({ path }, index) => (
              <div key={index}>
                <LinkItem key={path}>
                  <Link onClick={handleToggleMenu} to={isOpen ? path : "#"}>
                    {isOpen
                      ? t(`navigation.${path}`)
                      : index === 0
                      ? t(`navigation.${currentLocation}`)
                      : t(`navigation.${path}`)}
                  </Link>
                </LinkItem>
              </div>
            ))}
          </LinkContainer>
        </LinkGroup>
      </motion.div>
    </>
  )
}

export default NavigationMenu
