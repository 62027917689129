import React, { useState } from "react"
import { ClickAwayListener, IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import SelectFloorPlans from "screens/SelectFloorPlans"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { toast } from "react-toastify"
import brochureFile from "./The_Wales_Brochure.pdf"

const FLOORS = [
  { name: "2A", active: false },
  { name: "2B", active: false },
  { name: "2C", active: false },
  { name: "3A", active: false },
  { name: "3C", active: false },
  { name: "4A", active: false },
  { name: "4B", active: false },
  { name: "4C", active: false },
  { name: "5A", active: false },
  { name: "5B", active: false },
  { name: "5C", active: false },
  { name: "6A", active: false },
  { name: "6B", active: false },
  { name: "7A", active: false },
  { name: "7B", active: false },
  { name: "8A", active: false },
  { name: "8B", active: false },
  { name: "9A", active: false },
  { name: "9B", active: false },
  { name: "Penthouse", active: false }
]

const Share = props => {
  const { onSidebar, selectedFloor } = props
  const [showMore, setShowMore] = useState(false)
  const [floors, setFloors] = useState(FLOORS)
  const [showPlans, setShowPlans] = useState(false)

  const handleChangeFloor = index => {
    const temp = [...floors]
    temp.splice(index, 1, {
      ...temp[index],
      active: !temp[index].active
    })
    setFloors(temp)
  }

  const handleCopyToShareLink = () => {
    const strFloors =
      selectedFloor +
      "," +
      floors
        .filter(floor => floor.active)
        .map(floor => floor.name)
        .join(",")

    const linkToGo =
      window.location.origin + `/select_floor_plans?floors=${strFloors}`

    if (typeof navigator.clipboard == "undefined") {
      var textArea = document.createElement("textarea")
      textArea.value = linkToGo
      textArea.style.position = "fixed" //avoid scrolling to bottom
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        var successful = document.execCommand("copy")
        var msg = successful ? "Copied!" : "unsuccessful"
        toast.info(msg)
      } catch (err) {
        toast.warning("Was not possible to copy te text: ", err)
      }

      document.body.removeChild(textArea)
      return
    }
    navigator.clipboard.writeText(linkToGo).then(
      function () {
        toast.info(`Copied!`)
      },
      function (err) {
        toast.warning("unsuccessful!", err)
      }
    )
  }

  const printDocument = () => {
    toast("Downloading...")
    setShowPlans(true)
    setTimeout(() => {
      const input = document.getElementById("select_floor_plans_page")
      html2canvas(input, { dpi: 144 }).then(canvas => {
        setShowPlans(false)
        const imgData = canvas.toDataURL("image/png", 1.0)
        var imgWidth = 230
        var pageHeight = 295
        var doc = new jsPDF("p", "mm", [230, 295], true)
        var position = 0
        doc.addImage(
          imgData,
          "PNG",
          0,
          position,
          imgWidth,
          pageHeight,
          undefined,
          "FAST"
        )

        const strFloors =
          selectedFloor +
          "_" +
          floors
            .filter(floor => floor.active)
            .map(floor => floor.name)
            .join("_")
        doc.save(`The Wales Residences ${strFloors}.pdf`)
      })
    }, 500)
  }

  return (
    <>
      <div className="share" id="share-page">
        <ClickAwayListener
          onClickAway={event => {
            event.preventDefault()
            onSidebar(0)
          }}
        >
          <div className="share-container">
            <div className="share-header">
              <span className="title">Share</span>
              <div className="action-wrapper">
                <IconButton onClick={printDocument}>
                  <img
                    className="action"
                    src="/assets/images/icon/download_icon_purple.svg"
                    alt="pdf"
                  />
                </IconButton>
                <IconButton onClick={handleCopyToShareLink}>
                  <img
                    className="action"
                    src="/assets/images/icon/link_icon.svg"
                    alt="pdf"
                  />
                </IconButton>
                <a href={brochureFile} download="The Wales Brochure.pdf">
                  <IconButton
                    onClick={() => {
                      toast("Downloading...")
                    }}
                  >
                    <img
                      className="action"
                      src="/assets/images/icon/brochure_icon_fill_download.svg"
                      alt="pdf"
                    />
                  </IconButton>
                </a>
              </div>
              <IconButton className="close-btn" onClick={() => onSidebar(0)}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="divider"></div>
            <div className="more-action-container">
              <IconButton
                className="action"
                onClick={() => setShowMore(!showMore)}
              >
                <img src="/assets/images/icon/more_icon.svg" alt="more" />
              </IconButton>
            </div>
            {showMore && (
              <div className="more-container">
                {floors.map((floor, index) => (
                  <div
                    onClick={() => handleChangeFloor(index)}
                    key={index}
                    className={`floor-btn ${floor.active ? "active" : null}`}
                  >
                    {floor.name === "Penthouse" ? "PH" : floor.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </ClickAwayListener>
      </div>
      {showPlans && (
        <SelectFloorPlans
          activeFloors={[selectedFloor].concat(
            floors.filter(floor => floor.active).map(floor => floor.name)
          )}
        />
      )}
    </>
  )
}

export default Share
