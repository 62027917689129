import React, { useRef, useState, useEffect } from "react"
import { Html } from "@react-three/drei"

export default function Cell({ mesh, onHover, setAnno }) {
  const ref = useRef()
  const [hovered, setHover] = useState(false)
  const handleOnClick = () => {
    setAnno(mesh.name)
  }
  useEffect(
    () => void (document.body.style.cursor = hovered ? "pointer" : "default"),
    [hovered]
  )
  return (
    <mesh
      ref={ref}
      onPointerOver={e => {
        setHover(true)
        onHover(ref)
      }}
      onPointerOut={() => {
        setHover(false)
        onHover(null)
      }}
      onClick={handleOnClick}
      geometry={mesh.geometry}
      position={[mesh.position.x, mesh.position.y, mesh.position.z]}
      onHover={ref}
    >
      <meshBasicMaterial
        color={"#B3B0C4"}
        opacity={hovered ? 0.5 : 0}
        depthTest={false}
        transparent
      />
      <Html
        distanceFactor={2}
        visible={hovered}
        // occlude
        // onOcclude={setHover}
        style={{
          transition: "all 0.5s",
          opacity: hovered ? 1 : 0,
          transform: `scale(${hovered ? 1 : 0.5})`
        }}
      >
        <div className="plan_finder_3d_canvas_content">{mesh.name}</div>
      </Html>
    </mesh>
  )
}
