import Gallery3D from "components/Gallery3D"
import AppsIcon from "@material-ui/icons/Apps"
import { IconButton } from "@material-ui/core"
import { motion } from "framer-motion"

const Start = 0.5
const Tiles = [
  {
    path: "/assets/images/gallery/gallery-image-01.jpg",
    label: "",
    pos: {
      x: 0 + Start,
      y: 0
    }
  },
  {
    path: "/assets/images/gallery/gallery-image-02.jpg",
    label: "",
    pos: {
      x: 1.8 + Start,
      y: 0
    }
  },
  {
    path: "/assets/images/gallery/gallery-image-03.jpg",
    label: "",
    pos: {
      x: 3.6 + Start,
      y: 0
    }
  },
  {
    path: "/assets/images/gallery/gallery-image-04.jpg",
    label: "",
    pos: {
      x: 5.7 + Start,
      y: 0
    }
  },
  {
    path: "/assets/images/gallery/gallery-image-05.jpg",
    label: "",
    pos: {
      x: 8 + Start,
      y: 0
    }
  },
  {
    path: "/assets/images/gallery/image-01.jpg",
    label: "",
    pos: {
      x: 10.3 + Start,
      y: 0.2
    }
  },
  {
    path: "/assets/images/gallery/image-02.jpg",
    label: "",
    pos: {
      x: 12.6 + Start,
      y: -0.3
    }
  },
  {
    path: "/assets/images/gallery/image-03.jpg",
    label: "",
    pos: {
      x: 15 + Start,
      y: 0.2
    }
  },
  {
    path: "/assets/images/gallery/image-04.jpg",
    label: "",
    pos: {
      x: 17.1 + Start,
      y: -0.3
    }
  },
  {
    path: "/assets/images/gallery/image-05.jpg",
    label: "",
    pos: {
      x: 19.3 + Start,
      y: 0.2
    }
  },
  {
    path: "/assets/images/gallery/image-06.jpg",
    label: "",
    pos: {
      x: 21.7 + Start,
      y: -0.3
    }
  },
  {
    path: "/assets/images/gallery/image-07.jpg",
    label: "",
    pos: {
      x: 23.9 + Start,
      y: 0.2
    }
  },
  {
    path: "/assets/images/gallery/image-08.jpg",
    label: "",
    pos: {
      x: 26.1 + Start,
      y: -0.3
    }
  },
  {
    path: "/assets/images/gallery/image-09.jpg",
    label: "",
    pos: {
      x: 27.7 + Start,
      y: 0.2
    }
  }
]
const Title = {
  main: "",
  sub: ""
}

const pageVariants = {
  init: {
    opacity: 0
  },
  show: {
    opacity: 1
  },
  hidden: {
    opacity: 0
  }
}

const pageTransition = {
  ease: "easeOut",
  duration: 1
}

export default function GalleryCarousel({ history }) {
  const handleToggle = () => {
    history.replace("/media/gallery/grid/1")
  }
  return (
    <motion.div
      className="gallery-carousel"
      variants={pageVariants}
      transition={pageTransition}
      animate="show"
    >
      <IconButton onClick={handleToggle} className="grid-button">
        <AppsIcon fontSize="large" />
      </IconButton>
      <Gallery3D title={Title} tiles={Tiles} speed={0} length={29.3} />
    </motion.div>
  )
}
