import * as THREE from "three"
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer"
// eslint-disable-next-line
import { gsap, Expo, Circ, Power0, Power1, Power2, Power3, Power4 } from "gsap"
import { FragShader } from "./glsl/tile.frag"
import { VertexShader } from "./glsl/tile.vert"
import { TILE_MOVEMENT_DIRECTION } from "./Gallery3D.Constants"

export default class Tile extends THREE.Object3D {
  // eslint-disable-next-line
  constructor(texture, label, position) {
    super()

    this.label = label
    this.initialPosition = position
    this.position.y = this.initialPosition.y

    //Create animated image
    this.natureWidth = texture.image.width
    this.natureHeight = texture.image.height

    this.width = texture.image.width / 1200
    this.height = texture.image.height / 1200

    this.extra = 0

    this.uniforms = {
      uTexture: {
        type: "sampler2D",
        value: texture
      },
      uOpacity: {
        type: "float",
        value: 0
      },
      uOpacityHover: {
        type: "float",
        value: 0
      },
      uHoverProgress: {
        type: "float",
        value: 0
      },
      uDirection: {
        type: "f",
        value: 0
      },
      uIntensity: {
        type: "f",
        value: 0
      },
      uLimitCurve: {
        type: "f",
        value: 0.05
      },
      uLimitShear: {
        type: "f",
        value: 0.25
      },
      uColorBlend: {
        type: "f",
        value: 0.6
      },
      uOffsetNoise: {
        type: "vec2",
        value: [0, 1]
      },
      uColor: new THREE.Uniform(new THREE.Color(2965556))
    }

    const geometry = new THREE.PlaneBufferGeometry(1, 1, 8, 8)
    geometry.scale(this.width, this.height, 1)

    const material = new THREE.ShaderMaterial({
      vertexShader: VertexShader,
      fragmentShader: FragShader,
      uniforms: this.uniforms,
      side: THREE.DoubleSide,
      transparent: true,
      wireframe: false
    })

    const mesh = new THREE.Mesh(geometry, material)
    mesh.castShadow = true
    this.plane = mesh
    this.add(mesh)

    //Annotation
    this.createAnnotation()

    this.onAppear()
  }

  createAnnotation() {
    const div = document.createElement("div")
    this.annotation = document.createElement("span")
    this.annotation.classList.add("annotation")
    div.append(this.annotation)

    const annotation = new CSS2DObject(div)
    annotation.position.set(-this.width / 2, -this.height / 2, 0)
    this.add(annotation)
  }

  onAppear() {
    //Opacity anim
    gsap.killTweensOf(this.uniforms.uOpacity)
    gsap.to(this.uniforms.uOpacity, {
      value: 1,
      duration: 2,
      delay: 0,
      ease: Power1.easeInOut
    })

    //Wave&Color anim
    gsap.killTweensOf(this.uniforms.uHoverProgress)
    gsap.to(this.uniforms.uHoverProgress, {
      value: 1,
      duration: 2.2,
      delay: 0,
      ease: Power1.easeOut
    })

    //Appear annotation
    setTimeout(() => {
      this.annotation.innerHTML = this.label
      this.annotation.classList.add("animate__animated", "animate__fadeInRight")
    }, 1800)
  }

  onActive() {
    gsap.killTweensOf(this.uniforms.uHoverProgress)
    gsap.to(this.uniforms.uHoverProgress, {
      value: 1,
      duration: 0.8,
      delay: 0,
      ease: Power1.easeIn
    })
    this.annotation.classList.remove("active")
  }

  onInactive() {
    gsap.killTweensOf(this.uniforms.uHoverProgress)
    gsap.to(this.uniforms.uHoverProgress, {
      value: 0,
      duration: 0.8,
      delay: 0,
      ease: Power1.easeIn
    })
  }

  onPicked() {
    this.onActive()
    this.annotation.classList.add("active")
  }

  autoFlowAndDragdrop(x, direction, viewport, galleryLength) {
    //X
    this.position.x =
      this.initialPosition.x -
      viewport.width / 2 +
      this.width / 2 -
      x * viewport.width -
      this.extra

    //
    const planeOffset = this.width / 2
    const viewportOffset = viewport.width / 2

    let isBefore = this.position.x + planeOffset < -viewportOffset
    let isAfter = this.position.x - planeOffset > viewportOffset

    if (direction === TILE_MOVEMENT_DIRECTION.down && isBefore) {
      this.extra -= galleryLength
      isBefore = false
      isAfter = false
    }

    if (direction === TILE_MOVEMENT_DIRECTION.up && isAfter) {
      this.extra += galleryLength
      isBefore = false
      isAfter = false
    }
  }

  adjustScaleForCentroid(viewport) {
    const start = viewport.width / 2
    const x = Math.abs(this.position.x)
    if (x < start) {
      const scale = 1 + (start - x) * 0.38
      this.scale.x = scale
      this.scale.y = scale
    }
  }

  animate(x, direction, viewport, galleryLength) {
    this.autoFlowAndDragdrop(x, direction, viewport, galleryLength)
    this.adjustScaleForCentroid(viewport)
  }
}
