import ThemeProvider from "./theme"
import { AnimatePresence } from "framer-motion"
import { Switch, Route, Redirect } from "react-router"
import { BrowserRouter } from "react-router-dom"
import { createBrowserHistory } from "history"
import { Provider as StoreProvider } from "react-redux"
import { I18nextProvider } from "react-i18next"
import createStore from "state/store"
import i18n from "./i18n/i18n-config"
import DependencyProvider from "components/DependencyProvider"
import { primaryRoutes } from "./routes"
import SalesInfoPreload from "./SalesInfoPreload"
import dependencies from "./dependencies"
import NavigationMenu from "components/NavigationMenu"
import Lobby from "screens/Lobby/Lobby"
import SelectFloorPlans from "screens/SelectFloorPlans"
import { ToastContainer } from "react-toastify"
import { MuiThemeProvider, createTheme } from "@material-ui/core"
import "react-toastify/dist/ReactToastify.css"
import "./App.scss"

const history = createBrowserHistory()
const store = createStore(dependencies)

const basename = "/"

const theme = createTheme({
  palette: {
    primary: {
      main: "#545355"
    },
    secondary: {
      main: "#7500ed"
    }
  }
})

function App() {
  return (
    <ThemeProvider>
      <MuiThemeProvider theme={theme}>
        <DependencyProvider dependencies={dependencies}>
          <I18nextProvider i18n={i18n}>
            <StoreProvider store={store}>
              <BrowserRouter history={history} basename={basename}>
                <SalesInfoPreload />
                <AnimatePresence>
                  <Switch>
                    {primaryRoutes.map(({ path, component, exact }) => (
                      <Route
                        exact={exact}
                        key={path}
                        path={path}
                        component={component}
                      />
                    ))}
                    <Route path={"/lobby"} component={Lobby} />
                    <Route
                      path={"/select_floor_plans"}
                      component={SelectFloorPlans}
                    />
                    <Redirect to={"/"} />
                  </Switch>
                </AnimatePresence>
                <NavigationMenu routes={primaryRoutes} />
                <ToastContainer
                  position="top-right"
                  hideProgressBar={true}
                  // autoClose={true}
                  // newestOnTop={true}
                  // closeOnClick={true}
                  // draggable={false}
                  // rtl={false}
                />
              </BrowserRouter>
            </StoreProvider>
          </I18nextProvider>
        </DependencyProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  )
}

export default App
