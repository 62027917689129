import { IconButton } from "@material-ui/core"
import React, { useState } from "react"

const ResidenceTour = props => {
  const { onSidebar } = props
  const [showTour, setShowTour] = useState(false)

  return (
    <div className="residence_tour_page">
      {!showTour ? (
        <div className="page-container">
          <img
            src="/assets/images/gallery/image-02.jpg"
            className="media-image"
            alt="media"
          />
          <div className="media-content">
            <div className="residence_tour">
              <div className="circle" onClick={() => setShowTour(true)}>
                <div className="small-circle">
                  <span>Residence Tour</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <iframe
          title="tour"
          width="100%"
          height="100vh"
          style={{
            width: "100%",
            height: "100vh",
            border: "none",
            maxWidth: "100%"
          }}
          frameborder="0"
          allowfullscreen
          allow="xr-spatial-tracking; gyroscope; accelerometer"
          scrolling="no"
          src="https://kuula.co/share/collection/7kXcP?logo=-1&info=0&fs=1&vr=1&sd=1&thumbs=1"
        ></iframe>
      )}
      <IconButton onClick={() => onSidebar(0)} className="back">
        <img src="/assets/images/icon/back_arrow.svg" alt="back" />
      </IconButton>
    </div>
  )
}

export default ResidenceTour
