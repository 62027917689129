import { Root } from "./Toggle.style"

export const OpenToggleButton = ({ className, onClick, isPurple = false }) => (
  <Root className={className} onClick={onClick}>
    <img
      src={`/assets/images/${
        isPurple ? "navigation-purple.png" : "navigation.png"
      }`}
      style={{ width: "100%" }}
      alt=""
    />
  </Root>
)

export const CloseToggleButton = ({ className, onClick }) => (
  <Root className={className} onClick={onClick}>
    <img src="/assets/images/menu_button_close.svg" alt="" />
  </Root>
)
