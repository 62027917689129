import styled from "styled-components/macro"

export const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  outline: none;
  background-color: ${({
    theme: {
      palette: { defaultBG }
    }
  }) => defaultBG};
`
