import React from "react"
import { AnimatePresence } from "framer-motion"
import { Switch, Route, Redirect } from "react-router"
import Gallery from "./Gallery"
import ResidenceTour from "./ResidenceTour"

const MainMedia = props => {
  return (
    <div className="media">
      <img
        src="/assets/images/gallery/image-01.jpg"
        className="media-image"
        alt="media"
      />
      <div className="media-content">
        <div className="gallery-content">
          <div
            className="circle"
            onClick={() => props.history.push("/media/gallery")}
          >
            <div className="small-circle">
              <span>Gallery</span>
            </div>
          </div>
        </div>
        <div className="residence-content">
          <div
            className="circle"
            onClick={() => props.history.push("/media/residence_tour")}
          >
            <div className="small-circle">
              <span>Residence Tour</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Media({ match: { url } }) {
  return (
    <AnimatePresence>
      <Switch>
        <Route exact path={url} component={MainMedia} />
        <Route path={`${url}/gallery`} component={Gallery} />
        <Route path={`${url}/residence_tour`} component={ResidenceTour} />
        <Redirect to={"/"} />
      </Switch>
    </AnimatePresence>
  )
}

export default Media
