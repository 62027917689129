import styled from "styled-components/macro"

export const Root = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
`
