import { IconButton } from "@material-ui/core"
import { motion } from "framer-motion"
import { useState } from "react"
import Div100vh from "react-div-100vh"
import { Link } from "react-router-dom"

const lobbythumb = "/assets/images/gallery/gallery-image-03.jpg"
const gymthumb = "/assets/images/gallery/gallery-image-04.jpg"

const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
}

const pageTransition = {
  ease: "easeInOut",
  duration: 0.8
}

const lobbyVariants = {
  initial: {
    x: "-100%"
  },
  in: {
    x: 0
  },
  out: {
    x: 0
  }
}

const lobbyTransition = {
  ease: "easeInOut",
  duration: 0.5
}

const gymVariants = {
  initial: {
    x: "100%"
  },
  in: {
    x: 0
  },
  out: {
    x: 0
  }
}

const gymTransition = {
  ease: "easeInOut",
  duration: 0.5
}

const Lobby = ({ match: { url } }) => {
  const [isActive, setIsActive] = useState(0)

  return (
    <Div100vh className="lobby">
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
      >
        {isActive === 0 ? (
          <div className="media-content">
            <div
              className="gallery-content"
              style={{
                backgroundImage: `url(${lobbythumb})`
              }}
            >
              <div className="circle" onClick={() => setIsActive(1)}>
                <div className="small-circle">
                  <span>Lobby</span>
                </div>
              </div>
            </div>
            <div
              className="residence-content"
              style={{ backgroundImage: `url(${gymthumb})` }}
            >
              <div className="circle" onClick={() => setIsActive(2)}>
                <div className="small-circle">
                  <span>Gym</span>
                </div>
              </div>
            </div>
          </div>
        ) : isActive === 1 ? (
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={lobbyVariants}
            transition={lobbyTransition}
            onClick={() => setIsActive(0)}
            className="lobby-content"
          >
            <img src={lobbythumb} style={{ height: "100vh" }} alt="lobby" />
          </motion.div>
        ) : (
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={gymVariants}
            transition={gymTransition}
            onClick={() => setIsActive(0)}
            className="lobby-content"
          >
            <img src={gymthumb} style={{ height: "100vh" }} alt="gym" />
          </motion.div>
        )}
        <Link className="back" to="/">
          <IconButton style={{ padding: "1em" }}>
            <img src="/assets/images/icon/back_arrow.svg" alt="back" />
          </IconButton>
        </Link>
      </motion.div>
    </Div100vh>
  )
}

export default Lobby
