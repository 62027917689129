import React, { useEffect, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import floorplansFile from "./floorplans.pdf"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { makeStyles } from "@material-ui/core/styles"
import StyledRadio from "./StyledRadio"
import { isDesktop } from "react-device-detect"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const useStyles = makeStyles({
  penthouse_select: {
    flexDirection: "row"
  },
  controlLabel: {
    margin: 0
  }
})

export default function FloorPlanContent({ data }) {
  const [pageNumber, setPageNumber] = useState(0)
  const classes = useStyles()

  useEffect(() => {
    setPageNumber(data.pdf_page)
  }, [data])

  return (
    <div className="pdf-area">
      <TransformWrapper>
        <TransformComponent
          wrapperStyle={{
            cursor: "zoom-in"
          }}
        >
          <Document file={floorplansFile} loading="">
            <Page
              renderMode="canvas"
              scale={isDesktop ? 3 : 1}
              className="floorplan-page"
              pageNumber={pageNumber}
              loading=""
            />
          </Document>
        </TransformComponent>
      </TransformWrapper>

      {data.residence_number === "Penthouse" && (
        <div className="pdf-controls">
          <RadioGroup
            aria-label="penthouse_select"
            name="penthouse"
            value={pageNumber}
            onChange={event => {
              setPageNumber(Number(event.target.value))
            }}
            className={classes.penthouse_select}
          >
            <FormControlLabel
              className={classes.controlLabel}
              value={9}
              control={<StyledRadio />}
              label=""
            />
            <FormControlLabel
              className={classes.controlLabel}
              value={10}
              control={<StyledRadio />}
              label=""
            />
          </RadioGroup>
        </div>
      )}
    </div>
  )
}
