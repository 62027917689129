import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    defaultNS: ["translation"],
    resources: {
      en: {
        translation: require("./en.json")
      }
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    },
    react: {
      useSuspense: false,
      transKeepBasicHtmlNodesFor: [
        "p",
        "li",
        "b",
        "i",
        "em",
        "br",
        "strong",
        "span"
      ]
    }
  })

i18n.changeLanguage()

export default i18n
