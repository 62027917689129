import { useState, useEffect } from "react"
import Cover from "components/Cover"
import { BackgroundImage, ImageAndChildren } from "./CoverImage.style"

const CoverImage = ({ imgUrl, children }) => {
  const [aspectRatio, setAspectRatio] = useState()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setAspectRatio(0)
    setIsVisible(false)
    const img = new Image()
    img.onload = () => {
      let r = Number(img.naturalWidth) / Number(img.naturalHeight)
      setAspectRatio(r)
      setTimeout(() => {
        setIsVisible(true)
      }, 50)
    }
    img.src = imgUrl
  }, [imgUrl])

  return (
    <Cover aspectRatio={aspectRatio}>
      {!!aspectRatio && (
        <ImageAndChildren isVisible={isVisible}>
          <BackgroundImage src={imgUrl} />
          {children}
        </ImageAndChildren>
      )}
    </Cover>
  )
}

export default CoverImage
