import styled from "styled-components/macro"

export const Root = styled.div`
  cursor: pointer;

  & > div {
    background-color: "#333333";
    height: 100%;
    width: 100%;

    transition: transform 0.5s ease;
    & > div {
      transition: transform 0.5s ease;
    }
  }

  &:hover {
    & > div {
      transform: scale(0.9);
      & > div {
        transform: scale(1.3);
      }
    }
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
`
