import React, { useState } from "react"

const ResidenceTour = props => {
  const [showTour, setShowTour] = useState(false)

  return (
    <div className="media">
      {!showTour ? (
        <>
          <img
            src="/assets/images/gallery/image-02.jpg"
            className="media-image"
            alt="media"
          />
          <div className="media-content">
            <div className="residence_tour">
              <div className="circle">
                <div className="small-circle">
                  <span onClick={() => setShowTour(true)}>Residence Tour</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <iframe
          title="tour"
          width="100%"
          height="100vh"
          style={{
            width: "100%",
            height: "100vh",
            border: "none",
            maxWidth: "100%"
          }}
          frameborder="0"
          allowfullscreen
          allow="xr-spatial-tracking; gyroscope; accelerometer"
          scrolling="no"
          src="https://kuula.co/share/collection/7kXcP?logo=-1&info=0&fs=1&vr=1&sd=1&thumbs=1"
        ></iframe>
      )}
    </div>
  )
}

export default ResidenceTour
