import { useCallback, useState } from "react"
import { generatePath } from "react-router"

import data from "./data"
import ModalImage from "./ModalImage"
import GridGalleryImage from "./GridGalleryImage"
import { Bottom } from "./Carnegie.style"
import { GridRoot, SpotsRoot, Spot } from "./CarnegieGrid.style"
import { IconButton } from "@material-ui/core"
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel"
import Div100vh from "react-div-100vh"

const PAGE_SIZE = 6

const CarnegieGrid = ({
  history,
  match: {
    url,
    path,
    params: { page: p }
  }
}) => {
  const page = Number(p)
  const first = (page - 1) * PAGE_SIZE
  const gridPage = data.slice(first, first + PAGE_SIZE)

  const [selected, setSelected] = useState({})

  const handleGoToPage = useCallback(
    page => {
      history.push(generatePath(path, { page }))
    },
    [history, path]
  )

  const handleCloseSelected = useCallback(() => {
    setSelected({})
  }, [])

  const handleToggle = () => {
    history.replace("/carnegie/carousel")
  }

  return (
    <Div100vh className="gallery-grid">
      <IconButton onClick={handleToggle} className="grid-button">
        <ViewCarouselIcon fontSize="large" />
      </IconButton>
      <GridRoot>
        {gridPage.map(({ label, text, thumb, large }, index) => (
          <GridGalleryImage
            key={index}
            url={getGalleryImgUrl(thumb)}
            label={label}
            text={text}
            onClick={() => setSelected({ label, text, filename: large })}
          />
        ))}
      </GridRoot>
      <Bottom>
        <Spots
          pages={Math.ceil(data.length / 6)}
          currentPage={page}
          onGoToPage={handleGoToPage}
        />
      </Bottom>
      <ModalImage
        url={getGalleryImgUrl(selected.filename)}
        label={selected.label}
        text={selected.text}
        onClose={handleCloseSelected}
      />
    </Div100vh>
  )
}

const getGalleryImgUrl = filename =>
  filename && `${process.env.PUBLIC_URL}/assets/images/carnegie/${filename}`

const Spots = ({ pages, currentPage, onGoToPage }) => (
  <SpotsRoot>
    {Array.from({ length: pages }).map((_, index) => {
      // console.log(currentPage, index);
      return (
        <Spot
          key={index}
          isActive={currentPage === index + 1}
          onClick={() => onGoToPage(index + 1)}
        />
      )
    })}
  </SpotsRoot>
)

export default CarnegieGrid
