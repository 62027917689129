import { useEffect, useRef, useState } from "react"
import ToggleDisplay from "react-toggle-display"
import * as THREE from "three"
import * as POSTPROCESSING from "postprocessing"
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader"
import { PMREMGenerator } from "three/src/extras/PMREMGenerator"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import {
  CSS2DObject,
  CSS2DRenderer
} from "three/examples/jsm/renderers/CSS2DRenderer"
// eslint-disable-next-line
import { gsap, Expo, Circ, Power0, Power1, Power2, Power3, Power4 } from "gsap"
import Loader from "../Loader"
import Composer from "./Gallery3D.Composer"
// eslint-disable-next-line
import { FitCameraToSelection, ShadowPlane } from "./Gallery3D.Helpers"
import {
  lerp,
  SPACE_SIZE,
  TILE_MOVEMENT_DIRECTION,
  isEventSupported,
  TILE_CREATE_INTERVAL
} from "./Gallery3D.Constants"
import Tile from "./Gallery3D.Tile"
import normalizeWheel from "normalize-wheel"
import "animate.css"
import "./Gallery3D.css"
import { motion } from "framer-motion"

const TitleVariants = {
  initial: {
    opacity: 0,
    y: 50
  },
  in: {
    opacity: 1,
    y: 0
  },
  out: {
    opacity: 0,
    y: 50
  }
}

const TitleTransition = {
  ease: "easeOut",
  duration: 0.8
}

const SCENE_BACKGROUND_COLOR = "#262626"
const SCENE_HIGHLIGHT_COLORS = [
  "#686AD6",
  "#658FED",
  "#60B6F9",
  "#43E2EB",
  "#77FCE1",
  "#F3E8D4",
  "#C7DAC1",
  "#8CD59C",
  "#269571",
  "#154D5E"
]

let color_index = 0

export default function Gallery3D({ title, tiles, speed, length }) {
  const titleData = title
  const tileData = tiles || []
  const galleryLength = length
  const gallerySpeed = speed
  const canvasContainer = useRef(null)
  const [showLoader, setShowLoader] = useState(true)
  const [titleAnimate, setTitleAnimate] = useState("in")

  useEffect(() => {
    let width = canvasContainer.current.offsetWidth
    let height = canvasContainer.current.offsetHeight

    let smaaSearchImage = null
    let smaaAreaImage = null

    const clock = new THREE.Clock()
    let composer = null

    const rayCaster = new THREE.Raycaster()
    const intersects = []

    const tiles = []
    let offeredTile = null
    let selectedTile = null
    let isFitCamera = false

    let scroll = {
      ease: 0.05,
      current: 0,
      target: 0,
      last: 0
    }

    let direction = TILE_MOVEMENT_DIRECTION.up

    let isPointerDown = false
    let start = 0
    let speed = gallerySpeed
    let viewport = {
      width: 0,
      height: 0
    }
    let screen = {
      width: width,
      height: height
    }

    let tileIntervalID

    /**
     * Scene
     */
    const scene = new THREE.Scene()
    scene.background = new THREE.Color(SCENE_BACKGROUND_COLOR)

    function setSceneColor(color) {
      gsap.killTweensOf(scene)
      gsap.to(scene.background, {
        r: new THREE.Color(color).r,
        g: new THREE.Color(color).g,
        b: new THREE.Color(color).b,
        duration: 1,
        delay: 0,
        ease: Power1.easeInOut
      })
    }

    //Root object
    const rootObject = new THREE.Object3D()
    scene.add(rootObject)

    /**
     * Lighter
     */
    const lightGroup = new THREE.Group()
    lightGroup.name = "LightGroup"

    const dirLight = new THREE.DirectionalLight(0xffffff, 0.1)
    dirLight.position.set(SPACE_SIZE * 0.5, SPACE_SIZE * 0.5, SPACE_SIZE)
    dirLight.target.position.set(0, 0, 0)
    dirLight.castShadow = true
    dirLight.shadow.mapSize.width = 2048
    dirLight.shadow.mapSize.height = 2048
    dirLight.shadow.camera.near = 0.01
    dirLight.shadow.camera.far = SPACE_SIZE * 2
    lightGroup.add(dirLight)

    scene.add(lightGroup)

    /**
     * Helper
     */
    // const axisHelper = new THREE.AxesHelper(5)
    // scene.add(axisHelper)

    // const shadowPlane = ShadowPlane()
    // scene.add(shadowPlane)

    /**
     * Camera
     */
    const CAMERA_Z = SPACE_SIZE * 0.3
    const camera = new THREE.PerspectiveCamera(
      50,
      width / height,
      0.01,
      SPACE_SIZE * 100
    )
    camera.position.set(0, 0, CAMERA_Z)
    camera.lookAt(0, 0, 0)

    function calcFov() {
      if (screen.width <= 600) {
        camera.fov = 100
      } else if (screen.width > 600 && screen.width <= 1024) {
        camera.fov = 80
      } else if (screen.width > 1024 && screen.width <= 1400) {
        camera.fov = 60
      } else {
        camera.fov = 50
      }
      camera.updateProjectionMatrix()
    }
    calcFov()

    function calcViewport() {
      //Calc viewport
      const fov = camera.fov * (Math.PI / 180)
      const h = 2 * Math.tan(fov / 2) * camera.position.z
      const w = h * camera.aspect
      viewport = {
        width: w,
        height: h
      }
    }
    calcViewport()

    /**
     * Resize & Render
     */
    function resizeRendererToDisplaySize() {
      const canvasWidth = renderer.domElement.offsetWidth
      const canvasHeight = renderer.domElement.offsetHeight
      const needResize = canvasWidth !== width || canvasHeight !== height
      if (needResize) {
        width = canvasWidth
        height = canvasHeight
        screen.width = width
        screen.height = height
        camera.aspect = width / height

        calcFov()
        calcViewport()

        camera.updateProjectionMatrix()
        renderer.setSize(width, height)
        renderer2D.setSize(width, height)
        composer.setSize(width, height)
      }
    }

    function render() {
      resizeRendererToDisplaySize()
      cameraController.update()
      renderer.render(scene, camera)
      renderer2D.render(scene, camera)
      if (composer) {
        composer.render(clock.getDelta())
      }
      animateTiles()
      requestAnimationFrame(render)
    }

    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
      powerPreference: "high-performance",
      antialias: false,
      stencil: false,
      depth: false
    })
    renderer.setSize(width, height, false)
    renderer.setPixelRatio(window.devicePixelRatio || 1)
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap

    canvasContainer.current.appendChild(renderer.domElement)

    //2D renderer
    const renderer2D = new CSS2DRenderer()
    renderer2D.setSize(width, height)
    renderer2D.domElement.className = "renderer2D"
    canvasContainer.current.appendChild(renderer2D.domElement)

    /**
     * Camera Controller
     */
    const cameraController = new OrbitControls(camera, renderer.domElement)
    cameraController.screenSpacePanning = true
    cameraController.enableRotate = false
    cameraController.enablePan = false
    cameraController.enableZoom = false
    cameraController.dampingFactor = 0.05
    cameraController.enableDamping = false

    /**
     * Load Assets
     */
    const loadingManager = new THREE.LoadingManager()
    loadingManager.onStart = (url, itemsLoaded, itemsTotal) => {
      setShowLoader(true)
    }
    loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      if (!showLoader) {
        setShowLoader(true)
      }
    }
    loadingManager.onLoad = () => {
      // createTitle()
      createTiles()
      render()
      setTimeout(() => {
        setShowLoader(false)
      }, 200)
    }

    const textureLoader = new THREE.TextureLoader(loadingManager)
    tileData.map(d => (d.texture = textureLoader.load(d.path)))

    //Load smaa images
    const smaaImageLoader = new POSTPROCESSING.SMAAImageLoader(loadingManager)
    smaaImageLoader.load(([search, area]) => {
      smaaSearchImage = search
      smaaAreaImage = area
      composer = Composer(
        renderer,
        scene,
        camera,
        smaaSearchImage,
        smaaAreaImage
      )
    })

    //Load env map
    const rgbeLoader = new RGBELoader(loadingManager)
    rgbeLoader
      .setDataType(THREE.UnsignedByteType)
      .load("/assets/textures/city1.hdr", texture => {
        const pg = new PMREMGenerator(renderer)
        pg.compileEquirectangularShader()
        const envMap = pg.fromEquirectangular(texture).texture
        scene.environment = envMap
        // scene.background = envMap
        texture.dispose()
        pg.dispose()
      })

    /**
     * Create tiles
     */
    function createTile() {
      if (tiles.length < tileData.length) {
        const d = tileData[tiles.length]
        if (d.texture === undefined) return
        const tile = new Tile(d.texture, d.label, d.pos)
        rootObject.add(tile)
        tiles.push(tile)
        intersects.push(tile.plane)
      } else {
        clearInterval(tileIntervalID)
      }
    }

    function createTiles() {
      tileIntervalID = setInterval(createTile, TILE_CREATE_INTERVAL)
    }

    function animateTiles() {
      scroll.target += speed
      scroll.current = lerp(scroll.current, scroll.target, scroll.ease)
      if (scroll.current > scroll.last) {
        direction = TILE_MOVEMENT_DIRECTION.down
        speed = gallerySpeed
      } else if (scroll.current < scroll.last) {
        direction = TILE_MOVEMENT_DIRECTION.up
        speed = -gallerySpeed
      }
      tiles.map(tile =>
        tile.animate(scroll.current / width, direction, viewport, galleryLength)
      )

      scroll.last = scroll.current
    }

    function disableUnselectedTiles() {
      for (let tile of tiles) {
        if (tile !== selectedTile) {
          tile.onInactive()
        }
      }
    }

    function enableAllTiles() {
      for (let tile of tiles) {
        tile.onActive()
      }
    }

    function fitCameraToTile() {
      isFitCamera = true
      const boundingBox = new THREE.Box3()
      boundingBox.setFromObject(selectedTile.plane)
      const center = new THREE.Vector3()
      boundingBox.getCenter(center)
      const size = new THREE.Vector3()
      boundingBox.getSize(size)
      const maxDim = Math.max(size.x, size.y, size.z)
      let cameraZ = Math.abs(maxDim)

      //Offset
      let ratio = 1
      const imageRatio = size.x / size.y
      const viewportRatio = viewport.width / viewport.height

      let offset = 5
      if (imageRatio > 1) {
        if (viewportRatio > 1) {
          offset = 5
          ratio = viewport.width / size.x
        } else {
          offset = 14
          ratio = viewport.height / size.x
        }
      } else {
        if (viewportRatio > 1) {
          offset = 5
          ratio = viewport.width / size.y
        } else {
          offset = 14
          ratio = viewport.height / size.y
        }
      }

      ratio = lerp(ratio, offset, 0.6)
      cameraZ *= offset / ratio

      gsap.killTweensOf(camera)
      gsap.to(camera.position, {
        x: selectedTile.position.x,
        y: selectedTile.position.y,
        z: cameraZ,
        duration: 0.8,
        delay: 0.4,
        ease: Power1.easeInOut
      })
      gsap.killTweensOf(cameraController)
      gsap.to(cameraController.target, {
        x: selectedTile.position.x,
        y: selectedTile.position.y,
        duration: 0.8,
        delay: 0.4,
        ease: Power1.easeInOut
      })
    }

    function backCameraToTile() {
      isFitCamera = false
      gsap.killTweensOf(camera)
      gsap.to(camera.position, {
        x: 0,
        y: 0,
        z: CAMERA_Z,
        duration: 0.8,
        delay: 0,
        ease: Power1.easeInOut
      })
      gsap.killTweensOf(cameraController)
      gsap.to(cameraController.target, {
        x: 0,
        y: 0,
        duration: 0.8,
        delay: 0,
        ease: Power1.easeInOut
      })
    }

    // eslint-disable-next-line
    function createTitle() {
      const div = document.createElement("div")
      div.classList.add("titleWraper")
      const pivot = document.createElement("div")
      pivot.classList.add("pivot")
      div.append(pivot)
      const main = document.createElement("div")
      main.innerHTML = titleData.main
      main.classList.add("main", "animate__animated", "animate__fadeIn")
      pivot.append(main)
      const sub = document.createElement("div")
      sub.innerHTML = titleData.sub
      sub.classList.add("sub", "animate__animated", "animate__fadeIn")
      pivot.append(sub)

      const titleObject = new CSS2DObject(div)
      titleObject.position.set(-viewport.width / 2, viewport.height / 2, 0)
      rootObject.add(titleObject)
    }

    renderer.render(scene, camera)
    /**
     * Event & Dispose
     */
    function onWheel(event) {
      if (isFitCamera) return

      const normalized = normalizeWheel(event)
      const speed = normalized.pixelY
      scroll.target += speed * 0.5

      if (titleAnimate === "in") setTitleAnimate("out")
    }
    function onPointerDown(event) {
      isPointerDown = true
      scroll.position = scroll.current
      start = event.touches ? event.touches[0].clientX : event.clientX

      //Pick a tile up
      {
        const pickedPoint = new THREE.Vector2(
          ((event.touches ? event.changedTouches[0].pageX : event.offsetX) /
            width) *
            2 -
            1,
          -(
            (event.touches ? event.changedTouches[0].pageY : event.offsetY) /
            height
          ) *
            2 +
            1
        )
        rayCaster.setFromCamera(pickedPoint, camera)
        let pickedObjs = rayCaster.intersectObjects(intersects)
        if (pickedObjs.length > 0) {
          offeredTile = pickedObjs[0].object.parent
        } else {
          offeredTile = null
        }
      }
    }
    function onPointerUp(event) {
      isPointerDown = false

      //Pick a tile up
      // eslint-disable-next-line
      {
        const pickedPoint = new THREE.Vector2(
          ((event.touches ? event.changedTouches[0].pageX : event.offsetX) /
            width) *
            2 -
            1,
          -(
            (event.touches ? event.changedTouches[0].pageY : event.offsetY) /
            height
          ) *
            2 +
            1
        )
        rayCaster.setFromCamera(pickedPoint, camera)
        let pickedObjs = rayCaster.intersectObjects(intersects)
        if (pickedObjs.length > 0) {
          if (offeredTile === pickedObjs[0].object.parent) {
            selectedTile = offeredTile
          } else {
            selectedTile = null
          }
        } else {
          selectedTile = null
        }
      }

      //Animation for a picked tile
      // eslint-disable-next-line
      {
        if (selectedTile) {
          selectedTile.onPicked()
          disableUnselectedTiles()
          fitCameraToTile()
          setSceneColor(SCENE_HIGHLIGHT_COLORS[color_index++])
          if (color_index === SCENE_HIGHLIGHT_COLORS.length) color_index = 0
        } else {
          enableAllTiles()
          backCameraToTile()
          setSceneColor(SCENE_BACKGROUND_COLOR)
        }
      }
    }
    function onPointerMove(event) {
      //Pick a tile up
      {
        const pickedPoint = new THREE.Vector2(
          ((event.touches ? event.changedTouches[0].pageX : event.offsetX) /
            width) *
            2 -
            1,
          -(
            (event.touches ? event.changedTouches[0].pageY : event.offsetY) /
            height
          ) *
            2 +
            1
        )
        rayCaster.setFromCamera(pickedPoint, camera)
        let pickedObjs = rayCaster.intersectObjects(intersects)
        if (pickedObjs.length > 0) {
          document.body.style.cursor = "pointer"
        } else {
          document.body.style.cursor = "default"
        }
      }

      if (isPointerDown) {
        const x = event.touches ? event.touches[0].clientX : event.clientX
        const distance = (start - x) * 2
        scroll.target = scroll.position + distance

        if (titleAnimate === "in") setTitleAnimate("out")
      }

      //
      offeredTile = null
    }
    // Add event listeners
    canvasContainer.current.addEventListener("mousewheel", onWheel)
    canvasContainer.current.addEventListener("wheel", onWheel)
    if (isEventSupported("onpointerdown")) {
      canvasContainer.current.addEventListener(
        "pointerdown",
        onPointerDown,
        false
      )
      canvasContainer.current.addEventListener(
        "pointermove",
        onPointerMove,
        false
      )
      canvasContainer.current.addEventListener("pointerup", onPointerUp, false)
      canvasContainer.current.addEventListener(
        "pointercancel",
        onPointerUp,
        false
      )
      canvasContainer.current.addEventListener("pointerout", onPointerUp, false)
    } else if (isEventSupported("ontouchstart")) {
      canvasContainer.current.addEventListener(
        "touchstart",
        onPointerDown,
        false
      )
      canvasContainer.current.addEventListener(
        "touchmove",
        onPointerMove,
        false
      )
      canvasContainer.current.addEventListener("touchend", onPointerUp, false)
      canvasContainer.current.addEventListener(
        "touchcancel",
        onPointerUp,
        false
      )
    } else if (isEventSupported("onmousedown")) {
      canvasContainer.current.addEventListener(
        "mousedown",
        onPointerDown,
        false
      )
      canvasContainer.current.addEventListener(
        "mousemove",
        onPointerMove,
        false
      )
      canvasContainer.current.addEventListener("mouseup", onPointerUp, false)
      canvasContainer.current.addEventListener("mouseleave", onPointerUp, false)
      canvasContainer.current.addEventListener("mouseout", onPointerUp, false)
    }

    return () => {
      // eslint-disable-next-line
      if (canvasContainer.current) {
        canvasContainer.current.removeEventListener("mousewheel", onWheel)
        canvasContainer.current.removeEventListener("wheel", onWheel)
        if (isEventSupported("onpointerdown")) {
          canvasContainer.current.removeEventListener(
            "pointerdown",
            onPointerDown,
            false
          )
          canvasContainer.current.removeEventListener(
            "pointermove",
            onPointerMove,
            false
          )
          canvasContainer.current.removeEventListener(
            "pointerup",
            onPointerUp,
            false
          )
          canvasContainer.current.removeEventListener(
            "pointercancel",
            onPointerUp,
            false
          )
          canvasContainer.current.removeEventListener(
            "pointerout",
            onPointerUp,
            false
          )
        } else if (isEventSupported("ontouchstart")) {
          canvasContainer.current.removeEventListener(
            "touchstart",
            onPointerDown,
            false
          )
          canvasContainer.current.removeEventListener(
            "touchmove",
            onPointerMove,
            false
          )
          canvasContainer.current.removeEventListener(
            "touchend",
            onPointerUp,
            false
          )
          canvasContainer.current.removeEventListener(
            "touchcancel",
            onPointerUp,
            false
          )
        } else if (isEventSupported("onmousedown")) {
          // eslint-disable-next-line
          canvasContainer.current.removeEventListener(
            "mousedown",
            onPointerDown,
            false
          )
          // eslint-disable-next-line
          canvasContainer.current.removeEventListener(
            "mousemove",
            onPointerMove,
            false
          )
          // eslint-disable-next-line
          canvasContainer.current.removeEventListener(
            "mouseup",
            onPointerUp,
            false
          )
          // eslint-disable-next-line
          canvasContainer.current.removeEventListener(
            "mouseleave",
            onPointerUp,
            false
          )
          // eslint-disable-next-line
          canvasContainer.current.removeEventListener(
            "mouseout",
            onPointerUp,
            false
          )
        }
        // eslint-disable-next-line
        canvasContainer.current.innerHTML = ""
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <ToggleDisplay if={showLoader}>
        <Loader />
      </ToggleDisplay>
      <div className="title-wraper">
        <motion.div
          initial="initial"
          animate={titleAnimate}
          variants={TitleVariants}
          transition={TitleTransition}
        >
          <div className="main">{titleData.main}</div>
          <div className="sub">{titleData.sub}</div>
        </motion.div>
      </div>
      <div className="canvasContainer" ref={canvasContainer}></div>
    </>
  )
}
