import { useMemo, useRef, useState, useEffect } from "react"
import { Root } from "./Cover.style"

const Cover = ({ aspectRatio, children }) => {
  const ref = useRef()
  const [resized, setResized] = useState(Date.now())

  const { left, top, width, height } = useMemo(() => {
    if (ref.current && resized) {
      const { width, height } = ref.current.getBoundingClientRect()

      const boundsAspect = width / height

      if (boundsAspect > aspectRatio) {
        let h = width / aspectRatio
        // eslint-disable-next-line
        if (h == "Infinity") h = width
        return {
          left: 0,
          top: (h - height) / -2,
          width,
          height: h
        }
      } else {
        const w = height * aspectRatio
        return {
          left: (w - width) / -2,
          top: 0,
          width: w,
          height
        }
      }
    } else {
      return {
        left: 0,
        top: 0,
        width: "100%",
        height: "100%"
      }
    }
  }, [aspectRatio, resized])

  useEffect(() => {
    const el = ref.current
    const observer = new ResizeObserver(entries => {
      setResized(Date.now())
    })
    observer.observe(el)
    return () => {
      observer.unobserve(el)
    }
  }, [])

  return (
    <Root ref={ref}>
      <div style={{ position: "absolute", top, left, width, height }}>
        {children}
      </div>
    </Root>
  )
}

export default Cover
