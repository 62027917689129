import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Radio from "@material-ui/core/Radio"

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: "50%",
    width: 8,
    height: 8,
    boxShadow: "none",
    backgroundColor: "#0005",
    backgroundImage: "none",
    "$root.Mui-focusVisible &": {
      outline: "none",
      outlineOffset: 0
    },
    "input:hover ~ &": {
      backgroundColor: "#0005"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#2E1A47",
    backgroundImage:
      "none",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "none",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#2E1A47"
    }
  }
})

// Inspired by blueprintjs
export default function StyledRadio(props) {
  const classes = useStyles()

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  )
}
