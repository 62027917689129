import React, { Suspense, useState } from "react"
import { Canvas } from "@react-three/fiber"
// eslint-disable-next-line
import { Environment } from "@react-three/drei"
import {
  EffectComposer,
  Outline,
  HueSaturation,
  BrightnessContrast
} from "@react-three/postprocessing"
import { BlendFunction, Resizer, KernelSize } from "postprocessing"
import CornerModel from "./models/Corner"
import FrontModel from "./models/Front"
import "./PlanFinder3D.css"

export default function PlanFinder3D({ product, setSelectedAnnotation }) {
  const [hovered, onHover] = useState(null)
  return (
    <Canvas
      dpr={[1, 2]}
      camera={{ position: [0, 1.71, 0], fov: 50 }}
      className="plan_finder_3d_canvas"
    >
      <Suspense fallback={null}>
        {/* <Environment
          background={false} // Whether to affect scene.background
          // files={["px.hdr", "nx.hdr", "py.hdr", "ny.hdr", "pz.hdr", "nz.hdr"]} // Array of cubemap files OR single equirectangular file
          // path={"/assets/textures/pisaHDR/"} // Path to the above file(s)
          preset="apartment" // Preset string (overrides files and path)
        ></Environment> */}
        <ambientLight intensity={0.8} />
        {/* <spotLight position={[0, 10, 0]} angle={0.15} penumbra={1} /> */}
        {/* <pointLight position={[0, 20, 0]} color="#56adff" /> */}
        {product === "corner" ? (
          <CornerModel onHover={onHover} setAnno={setSelectedAnnotation} />
        ) : (
          <FrontModel onHover={onHover} setAnno={setSelectedAnnotation} />
        )}
      </Suspense>
      <EffectComposer multisampling={8} autoClear={false}>
        <Outline
          selection={hovered ? [hovered] : undefined}
          selectionLayer={10} // selection layer
          blendFunction={BlendFunction.ALPHA} // set this to BlendFunction.ALPHA for dark outlines
          patternTexture={null} // a pattern texture
          edgeStrength={8.5} // the edge strength
          pulseSpeed={0.0} // a pulse speed. A value of zero disables the pulse effect
          visibleEdgeColor={0xb3b0c4} // the color of visible edges
          hiddenEdgeColor={0xb3b0c4} // the color of hidden edges
          width={Resizer.AUTO_SIZE} // render width
          height={Resizer.AUTO_SIZE} // render height
          kernelSize={KernelSize.LARGE} // blur kernel size
          blur={false} // whether the outline should be blurred
          xRay={true} // indicates whether X-Ray outlines are enabled
        />
        <HueSaturation
          blendFunction={BlendFunction.NORMAL} // blend mode
          hue={0.0} // hue in radians
          saturation={0.1} // saturation in radians
        />
        <BrightnessContrast
          brightness={0.1} // brightness. min: -1, max: 1
          contrast={0.2} // contrast: min -1, max: 1
        />
      </EffectComposer>
    </Canvas>
  )
}
