import styled from "styled-components/macro";

export const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Top = styled.div`
  height: ${({ theme: { spacing } }) => spacing(16)}px;
  padding: ${({ theme: { spacing } }) => spacing(3)}px;
  margin-right: ${({ theme: { spacing } }) => spacing(13)}px;
  display: flex;
  justify-content: flex-end;
`;

export const Bottom = styled.div`
  height: ${({ theme: { spacing } }) => spacing(16)}px;
  padding: ${({ theme: { spacing } }) => spacing(3)}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MiddleCarousel = styled.div`
  flex: 1;
  padding: ${({ theme: { spacing } }) => spacing(2)}px;
`;

export const LayoutToggle = styled.div`
  width: ${({ theme: { spacing } }) => spacing(7)}px;
  height: ${({ theme: { spacing } }) => spacing(7)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const LeftNav = styled.div`
  position: absolute;
  left: ${({ theme: { spacing } }) => spacing(3)}px;
  top: 50%;

  transform: translate(0, calc(-50% + 26px));
`;

export const RightNav = styled.div`
  position: absolute;
  right: ${({ theme: { spacing } }) => spacing(3)}px;
  top: 50%;
  width: 100px;

  transform: translate(0, calc(-50% + 26px));
`;
