import React, { useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import brochureFile from "./The_Wales_Brochure.pdf"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { Button, IconButton, ButtonGroup } from "@material-ui/core"
import { isWindows } from "react-device-detect"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const Brochure = props => {
  const { onSidebar } = props
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const onNextPage = () => {
    if (pageNumber === numPages) return

    setPageNumber(pageNumber + 1)
  }

  const onPreviousPage = () => {
    if (pageNumber === 1) return

    setPageNumber(pageNumber - 1)
  }

  return (
    <div className="brochure">
      <Document
        file={brochureFile}
        onLoadSuccess={onDocumentLoadSuccess}
        loading=""
      >
        <Page
          renderMode="canvas"
          scale={isWindows ? 2 : 1}
          className="brochure-page"
          pageNumber={pageNumber}
        />
      </Document>
      <ButtonGroup
        className="page-navigation"
        color="secondary"
        aria-label="outlined secondary button group"
      >
        <Button onClick={onPreviousPage} variant="contained" color="secondary">
          <ArrowBackIosIcon />
        </Button>
        <Button onClick={onNextPage} variant="contained" color="secondary">
          <ArrowForwardIosIcon />
        </Button>
      </ButtonGroup>
      <IconButton className="back" onClick={() => onSidebar(0)}>
        <img src={`/assets/images/icon/back_arrow.svg`} alt="back" />
      </IconButton>
    </div>
  )
}

export default Brochure
