import React, { useState } from "react"
import LeftSideBar from "./LeftSideBar"
import Div100vh from "react-div-100vh"
import FrontViewIcon from "../../styles/images/front_view.png"
import CornerViewIcon from "../../styles/images/corner_view.png"
import { Button } from "@material-ui/core"
import { SIDEBAR_VALUE } from "./data"
import FloorPlan from "./FloorPlan"
import Gallery from "./Gallery"
import ResidenceTour from "./ResidenceTour"
import Brochure from "./Brochure"
import Share from "./Share"
import PlanFinder3D from "components/PlanFinder3D"
import ViewPhoto from "./ViewPhoto"

function Residences() {
  const [isOpen, setIsOpen] = useState(false)
  const [floor, setFloor] = useState("")
  const [sidebarValue, setSidebarValue] = useState(0)
  const [viewState, setViewState] = useState(true)

  const handleViewStateChange = () => {
    setViewState(!viewState)
  }

  const handleAnnotationChange = value => {
    setIsOpen(true)
    setFloor(value)
  }

  const renderSidePages = value => {
    switch (value) {
      case SIDEBAR_VALUE.FLOOR_PLAN:
        return <FloorPlan floor={floor} onSidebar={setSidebarValue} />
      case SIDEBAR_VALUE.GALLERY:
        return <Gallery floor={floor} onSidebar={setSidebarValue} />
      case SIDEBAR_VALUE.VIEW:
        return <ViewPhoto floor={floor} onSidebar={setSidebarValue} />
      case SIDEBAR_VALUE.TOUR:
        return <ResidenceTour onSidebar={setSidebarValue} />
      case SIDEBAR_VALUE.BROCHURE:
        return <Brochure onSidebar={setSidebarValue} />
      case SIDEBAR_VALUE.SHARE:
        return <Share onSidebar={setSidebarValue} selectedFloor={floor} />
      default:
        return <></>
    }
  }

  return (
    <Div100vh className="residences">
      <LeftSideBar
        isOpen={isOpen}
        setOpen={value => setIsOpen(value)}
        floor={floor}
        onCallback={value => setSidebarValue(value)}
      />
      <Button
        className="view-mode-button"
        onClick={handleViewStateChange}
        variant="contained"
      >
        <img
          src={viewState ? CornerViewIcon : FrontViewIcon}
          alt="View mode"
        ></img>
      </Button>
      <div className="apartment">
        <PlanFinder3D
          product={viewState ? "corner" : "front"}
          setSelectedAnnotation={handleAnnotationChange}
        />
      </div>
      {renderSidePages(sidebarValue)}
    </Div100vh>
  )
}

export default Residences
