import { createSlice } from "@reduxjs/toolkit"
import { get } from "lodash"

const INITIAL_STATE = {
  price: {
    from: 100000,
    to: 1000000
  },
  aspect: [
    {
      id: "N",
      values: ["N", "NE", "NW"],
      selected: false
    },
    {
      id: "S",
      values: ["SE", "S", "SW"],
      selected: false
    },
    {
      id: "E",
      values: ["E", "SE", "NE"],
      selected: false
    },
    {
      id: "W",
      values: ["W", "SW", "NW"],
      selected: false
    }
  ],
  floor: [
    {
      id: "1-3",
      values: [1, 2, 3],
      selected: false
    }
  ],
  type: [
    {
      id: "ST",
      values: ["ST"],
      selected: false
    },
    {
      id: "1",
      values: ["1"],
      selected: false
    },
    {
      id: "2",
      values: ["2"],
      selected: false
    }
  ]
}

const apartmentFinder = createSlice({
  name: "finder",
  initialState: INITIAL_STATE,
  reducers: {
    setPriceRange: (state, { payload: { value } }) => {
      state.price.from = value[0]
      state.price.to = value[1]
    },
    toggleOption: (state, { payload: { category, id } }) => {
      let current = state[category].find(({ id: _id }) => _id === id)
      console.log("current:", JSON.stringify(current))
      current.selected = !current.selected
    },
    clearFilters: state => INITIAL_STATE
  },
  extraReducers: {}
})

export const { setPriceRange, toggleOption, clearFilters } =
  apartmentFinder.actions

export const apartmentFiltersSelector = state => get(state, "finder")

export default apartmentFinder.reducer
