import { AnimatePresence } from "framer-motion"
import { Switch, Route, Redirect } from "react-router"
import CarnegieCarousel from "./CarnegieCarousel"
import CarnegieGrid from "./CarnegieGrid"

const Carnegie = ({ match: { url } }) => (
  <AnimatePresence>
    <Switch>
      <Route path={`${url}/carousel`} component={CarnegieCarousel} />
      <Route path={`${url}/grid/:page`} component={CarnegieGrid} />
      <Route render={() => <Redirect to={`${url}/grid/1`} />} />
    </Switch>
  </AnimatePresence>
)

export default Carnegie
