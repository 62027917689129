import { createSlice, createNextState } from "@reduxjs/toolkit"
import { get } from "lodash"

const INITIAL_STATE = createNextState([], draft => draft)

const favourites = createSlice({
  name: "favourites",
  initialState: INITIAL_STATE,
  reducers: {
    initialise: (state, { payload: { apartmentIds } }) => {
      state.splice(0, state.length)
      apartmentIds.forEach(id => state.push(id))
    },
    addFavourite: (state, { payload: { id } }) => {
      state.push(id)
    },
    removeFavourite: (state, { payload: { id } }) => {
      return state.filter(_id => _id !== id)
    }
  }
})

export const { initialise, addFavourite, removeFavourite } = favourites.actions

export const favouriteIdsSelector = state => get(state, "favourites")

export const isFavouriteSelector = idToCheck => state =>
  !!favouriteIdsSelector(state).find(id => id === idToCheck)

export default favourites.reducer
