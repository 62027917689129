import React, { useState, useEffect } from "react"
import Div100vh from "react-div-100vh"
import { APARTMENT_DATA } from "../Residences/data"
import FloorPlan from "./FloorPlan"
// import html2canvas from 'html2canvas'
// import jsPDF from 'jspdf'

export const getQueryString = (search = window.location.search, name) => {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
  let r = search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

function SelectFloorPlans(props) {
  const [floors, setFloors] = useState([])
  const [selectedFloor, setSelectedFloor] = useState(null)
  const { activeFloors } = props
  useEffect(() => {
    if (props.location) {
      const qs_floors = getQueryString(props.location.search, "floors")
      if (qs_floors) setFloors(qs_floors.split(",").filter(floor => floor))
    }
  }, [props.location])

  useEffect(() => {
    if (activeFloors) setFloors(activeFloors)
  }, [activeFloors])

  const onSelectFloor = floor => {
    setSelectedFloor(floor)
  }

  // const printDocument = () => {
  //   const input = document.getElementById('select_floor_plans_page');
  //   html2canvas(input, { scale: 1.0 })
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  //       var imgWidth = 210;
  //       var pageHeight = 295;
  //       var doc = new jsPDF('p', 'mm');
  //       var position = 0;
  //       doc.addImage(imgData, 'PNG', 0, position, imgWidth, pageHeight);
  //       doc.save( 'file.pdf')
  //   });
  // }

  return (
    <Div100vh className="select_floor_plans_container">
      <div
        className={`${
          activeFloors ? "pdf_select_floor_plans" : "select_floor_plans"
        }`}
        id="select_floor_plans_page"
      >
        <div className="plans-header">
          <div className="icon-wrapper">
            <img src={`/assets/images/navigation.png`} alt="navigation" />
          </div>
          <div className="title-wrapper">
            <span>Select Floor Plans</span>
          </div>
        </div>
        <div className="plans-container">
          {floors.map((item, index) => (
            <div className="plan-item" key={index}>
              <div className="floor-area" onClick={() => onSelectFloor(item)}>
                <div className="floor-image">
                  <img
                    src={`/assets/images/residences/floor_plan/${APARTMENT_DATA[item].plan_image}`}
                    alt="navigation"
                  />
                </div>
                <div className="floor-bottom">
                  <div className="info-container">
                    <div className="room-count">
                      Bedrooms: {APARTMENT_DATA[item].bedrooms}
                    </div>
                    <div className="room-count">
                      Bathrooms: {APARTMENT_DATA[item].bathrooms}
                    </div>
                    <div className="interior">{`${APARTMENT_DATA[item].sqft_interior} / ${APARTMENT_DATA[item].sqm_interior}`}</div>
                    <div className="view">{APARTMENT_DATA[item].view}</div>
                  </div>
                  <span className="bottom-title">
                    {item === "Penthouse"
                      ? `${item} Residence`
                      : `Residence ${item}`}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="home-container">
          <div className="gallery-image">
            <img
              src={`/assets/images/gallery/gallery-image-01.jpg`}
              alt="gallery"
            />
          </div>
          <div className="text-container">
            <div className="home">Carnegie Hill's Living Landmark</div>
            <div className="text-content">
              Located one block from Central Park on the gentle rise of Carnegie
              Hill, The Wales has captured the elegance and distinction of this
              Upper East Side enclave for more than a century. Once a charming
              hotel attracting visitors from around the world, this
              distinguished landmark has been reimagined as a limited collection
              of gracious residences inspired by classic pre-war design.
            </div>
          </div>
        </div>
        <div className="gallery-container">
          <div className="gallery-wrapper">
            <div
              style={{
                background: `url(/assets/images/gallery/gallery-image-03.jpg)`
              }}
            ></div>
          </div>
          <div className="gallery-wrapper">
            <div
              style={{
                background: `url(/assets/images/gallery/image-05.jpg)`
              }}
            ></div>
          </div>
        </div>
        <div className="footer-container">
          <div className="contact">
            <div style={{ marginBottom: 20, fontSize: 20 }}>Contact:</div>
            <div style={{ marginBottom: 5 }}>T:212.842.1855</div>
            {/* <div style={{ marginBottom: 5 }}>F:212.683.0660</div> */}
            <a href="mailto:sales@walesny.com">E:sales@walesny.com</a>
          </div>
          <div className="address">
            1295 Madison Avenue
            <br />
            New York, NY 10128
          </div>
          <img src={`/assets/images/logo/condo-purpel.png`} alt="condo" />
        </div>
      </div>
      {selectedFloor && (
        <FloorPlan
          floor={selectedFloor}
          onClose={() => setSelectedFloor(null)}
        />
      )}
    </Div100vh>
  )
}

export default SelectFloorPlans
