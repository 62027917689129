export const SPACE_SIZE = 10
export const TILE_CREATE_INTERVAL = 900
export const TILE_MOVEMENT_DIRECTION = {
  down: 0,
  up: 1
}
export function lerp(p1, p2, t) {
  return p1 + (p2 - p1) * t
}

export function isEventSupported() {
  var TAGNAMES = {
    select: "input",
    change: "input",
    submit: "form",
    reset: "form",
    error: "img",
    load: "img",
    abort: "img"
  }
  function isEventSupported(eventName) {
    var el = document.createElement(TAGNAMES[eventName] || "div")
    eventName = "on" + eventName
    var isSupported = eventName in el
    if (!isSupported) {
      el.setAttribute(eventName, "return;")
      isSupported = typeof el[eventName] == "function"
    }
    el = null
    return isSupported
  }
  return isEventSupported
}
